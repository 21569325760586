import React from 'react';
import { Col, Form, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

const { Item } = Form;
const { Option } = Select;

export default function TrustpilotReviewsScraper() {
  const { t } = useTranslation();

  return (
    <SimpleService
      unitName='review'
      title={SERVICES_DATA.trustpilot_reviews_service.label}
      subTitle='Returns reviews from a list of businesses'
      icon={SERVICES_DATA.trustpilot_reviews_service.icon}
      startTaskButtonTitle='Export Reviews'
      serviceName='trustpilot_reviews_service'
      queriesLabel='URLs to company profiles, company IDs'
      queriesPlaceholder='https://www.trustpilot.com/review/outscraper.com&#10;outscraper.com'
      learnMoreUrl='https://outscraper.com/trustpilot-reviews-scraper'
      apiTag='Trustpilot/paths/~1trustpilot~1reviews/get'
      enrichmentFields={['site', 'domain', 'website']}
      defaultParams={{ sort: '', stars: [], languages: 'all' }}
      recentCutoffSorting={'recency'}
      sortingOptions={[
        { value: '', text: 'Most relevant (default)' },
        { value: 'recency', text: 'Most recent' },
      ]}
      ExtraItems={({ updateTask, task }) => {
        const { languages = '', stars = [] } = task;

        return <Space size={16} className='w-100' direction='vertical'>
          <Col xs={24} lg={6} xl={4}>
            <Item label={t('title.language', 'Language')}>
              <Select
                value={languages}
                onChange={(v) => updateTask({ languages: v })}
              >
                <Option key='all' value='all'>All</Option>
                <Option key='default' value=''>Default</Option>
              </Select>
            </Item>
          </Col>
          <Col xs={24} lg={6} xl={4}>
            <Item label={t('title.filtering')}>
              <Select
                allowClear
                mode='multiple'
                value={stars}
                onChange={(v) => updateTask({ stars: v })}
              >
                <Option key='5' value='5'>5</Option>
                <Option key='4' value='4'>4</Option>
                <Option key='3' value='3'>3</Option>
                <Option key='2' value='2'>2</Option>
                <Option key='1' value='1'>1</Option>
              </Select>
            </Item>
          </Col>
        </Space>;
      }}
    />
  );
}
