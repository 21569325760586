import React from 'react';

import GoogleSearchBase from '@Services//GoogleSearch/Base';
import { SERVICES_DATA } from '@shared/data/services';

export default function GoogleSearchNews() {
  return (
    <GoogleSearchBase
      isGoogleLocationSelect
      isGoogleRangeSelect
      title={SERVICES_DATA.google_search_news_service.label}
      subTitle='Returns news'
      icon={SERVICES_DATA.google_search_news_service.icon}
      serviceName='google_search_news_service'
      queriesPlaceholder='Bitcoin&#10;Business News&#10;Tech News'
      apiTag='Google/paths/~1google-search-news/get'
    />
  );
}
