import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function GoogleMapsContributorReviews() {
  return (
    <SimpleService
      unitName='review'
      title={SERVICES_DATA.google_maps_contributor_reviews_service.label}
      icon={SERVICES_DATA.google_maps_contributor_reviews_service.icon}
      subTitle='Returns reviews from a list of profiles'
      serviceName='google_maps_contributor_reviews_service'
      queriesPlaceholder='116992800507045820329&#10;https://www.google.com/maps/contrib/109743434949154249800/reviews'
      queriesLabel='Contributor IDs, Contributor URLs'
    />
  );
}
