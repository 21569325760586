import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function BookingSearchScraper() {
  return (
    <SimpleService
      unitName='record'
      title={SERVICES_DATA.booking_search_service.label}
      subTitle='Returns search results'
      icon={SERVICES_DATA.booking_search_service.icon}
      queriesLabel='Search URLs'
      serviceName='booking_search_service'
      learnMoreUrl='https://outscraper.com/booking-scraper/'
      queriesPlaceholder='https://www.booking.com/searchresults.html?ss=Rome%2C+Lazio%2C+Italy'
      apiTag='Booking/paths/~1booking~1search/get'
    />
  );
}
