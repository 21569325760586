import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function RedditSearchScraper() {
  return (
    <SimpleService
      unitName='post'
      title={SERVICES_DATA.reddit_search_service.label}
      subTitle='Returns search results'
      icon={SERVICES_DATA.reddit_comments_service.icon}
      serviceName='reddit_search_service'
      queriesPlaceholder='bitcoin&#10;https://www.reddit.com/search/?q=HIVE'
      defaultParams={{ sort: 'relevance' }}
      recentCutoffSorting={'new'}
      sortingOptions={[
        { value: 'relevance', text: 'Relevance' },
        { value: 'hot', text: 'Hot' },
        { value: 'top', text: 'Top' },
        { value: 'new', text: 'New' },
        { value: 'comments', text: 'Comment count' },
      ]}
    />
  );
}
