import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function TargetReviewsScraper() {
  return (
    <SimpleService
      unitName='review'
      title={SERVICES_DATA.target_reviews_service.label}
      startTaskButtonTitle='Export Reviews'
      subTitle='Returns reviews from a list of products'
      icon={SERVICES_DATA.target_reviews_service.icon}
      serviceName='target_reviews'
      queriesPlaceholder='https://www.target.com/p/apple-iphone-15-pro-max/-/A-89957794'
      apiTag='Reviews-and-Comments/paths/~1target~1reviews/get'
      defaultParams={{ sort: 'most_recent' }}
      recentCutoffSorting={'most_recent'}
      sortingOptions={[
        { value: 'most_recent', text: 'most recent' },
        { value: 'highest_rating', text: 'highest rating' },
        { value: 'lowest_rating', text: 'lowest rating' },
        { value: 'helpfulness_desc', text: 'most helpful' },
      ]}
    />
  );
}
