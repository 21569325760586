import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function G2ReviewsScraper() {
  return (
    <SimpleService
      unitName='review'
      title={SERVICES_DATA.g2_reviews_service.label}
      startTaskButtonTitle='Export Reviews'
      subTitle='Returns reviews from a list of products'
      icon={SERVICES_DATA.g2_reviews_service.icon}
      serviceName='g2_reviews'
      queriesPlaceholder='https://www.g2.com/products/outscraper/reviews'
      apiTag='Reviews-and-Comments/paths/~1g2~1reviews/get'
      defaultParams={{ sort: 'g2_default' }}
      recentCutoffSorting={'most_recent'}
      sortingOptions={[
        { value: 'g2_default', text: 'G2 Sort' },
        { value: 'most_recent', text: 'Most Recent' },
        { value: 'most_helpful', text: 'Most Helpful' },
        { value: 'highest_rated', text: 'Highest Rated' },
        { value: 'lowest_rated', text: 'Lowest Rated' },
      ]}
    />
  );
}
