import React from 'react';

import { services } from '@shared/data/services';
import SimpleService from '@Common/SimpleService';

export default function ReverseGeocoding() {
  const { unit, name, description, input_fields, icon } = services.reverse_geocoding;

  return (
    <SimpleService
      limitType={null}
      unitName={unit}
      title={name}
      subTitle={description}
      icon={icon}
      serviceName='reverse_geocoding'
      apiTag='Other-Services/paths/~1reverse-geocoding/get'
      queriesPlaceholder='37.427074,-122.1439166&#10;40.7449819,-74.0271321'
      queriesLabel='Coordinates (latitude + longitude)'
      enrichmentFields={input_fields}
    />
  );
}
