import React from 'react';

import { services } from '@shared/data/services';
import SimpleService from '@Common/SimpleService';

export default function Geocoding() {
  const { unit, name, description, input_fields, icon } = services.geocoding;

  return (
    <SimpleService
      limitType={null}
      unitName={unit}
      title={name}
      subTitle={description}
      icon={icon}
      serviceName='geocoding'
      apiTag='Other-Services/paths/~1geocoding/get'
      queriesPlaceholder='321 California Ave, Palo Alto, CA 94306&#10;Central Park, NY'
      queriesLabel='Addresses'
      enrichmentFields={input_fields}
    />
  );
}
