import React from 'react';

import { services } from '@shared/data/services';
import SimpleService from './Common/SimpleService';

export default function TrustpilotScraper() {
  return (
    <SimpleService
      limitType={null}
      unitName={services.trustpilot_service.unit}
      title={services.trustpilot_service.name}
      subTitle={services.trustpilot_service.description}
      icon={services.trustpilot_service.icon}
      serviceName='trustpilot_service'
      queriesLabel='URLs to company profiles, company IDs'
      queriesPlaceholder='https://www.trustpilot.com/review/outscraper.com&#10;outscraper.com'
      learnMoreUrl='https://outscraper.com/trustpilot-scraper'
      apiTag='Trustpilot/paths/~1trustpilot/get'
      enrichmentFields={services.trustpilot_service.input_fields}
      supportedEnrichmentServices={[]}
    />
  );
}
