import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function RedditCommentsScraper() {
  return (
    <SimpleService
      unitName='comment'
      title={SERVICES_DATA.reddit_comments_service.label}
      subTitle='Returns comments'
      icon={SERVICES_DATA.reddit_comments_service.icon}
      serviceName='reddit_comments'
      queriesPlaceholder='https://www.reddit.com/r/squidgame/comments/1hzfvu8/poor_456/'
      defaultParams={{ sort: 'best' }}
      recentCutoffSorting={'new'}
      apiTag='Reviews-and-Comments/paths/~1reddit-comments/get'
      sortingOptions={[
        { value: 'best', text: 'Best' },
        { value: 'top', text: 'Top' },
        { value: 'new', text: 'New' },
        { value: 'controversial', text: 'Controversial' },
        { value: 'old', text: 'Old' },
        { value: 'qa', text: 'Q&A' },
      ]}
    />
  );
}
