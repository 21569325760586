import React from 'react';

import SimpleService from '@Common/SimpleService';

export default function Doordash() {
  return (
    <SimpleService
      unitName='review'
      title='Doordash Scraper'
      subTitle='Returns restaurant data from Doordash'
      serviceName='doordash_service'
      queriesPlaceholder='https://www.doordash.com/store/mcdonalds-123456/'
      queriesLabel='URLs to Doordash restaurant pages'
    />
  );
}
