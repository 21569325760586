import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function ShopifyProductsScraper() {
  return (
    <SimpleService
      beta
      unitName='product'
      title={SERVICES_DATA.shopify_products_service.label}
      subTitle='Returns products from Shopify websites'
      icon={SERVICES_DATA.shopify_products_service.icon}
      serviceName='shopify_products'
      queriesLabel='Links to Shopify websites'
      queriesPlaceholder='https://rothys.com/collections/womens-shoes'
      learnMoreUrl='https://outscraper.com/shopify-scraper/'
    />
  );
}
