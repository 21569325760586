import React from 'react';

import { SERVICES_DATA } from '@shared/data/services';
import GoogleSearchBase from '@Services//GoogleSearch/Base';

export default function GoogleSearch() {
  return (
    <GoogleSearchBase
      showUule
      isGoogleRangeSelect
      title={SERVICES_DATA.google_search_service.label}
      subTitle='Returns search results'
      icon={SERVICES_DATA.google_search_service.icon}
      serviceName='google_search_service_v3'
      queriesPlaceholder='Iphone&#10;Bitcoin&#10;Avia tickets'
      videoTutorialLink='https://www.youtube.com/embed/SxGa_lXrCIU'
      learnMoreUrl='https://outscraper.com/google-search-scraper'
      tutorialLink='https://outscraper.com/scraping-google-search-results'
      apiTag='Google/paths/~1google-search-v3/get'
      maxLimitPagesPerQuery={30}
      supportedEnrichmentServices={['domains_service', 'emails_validator_service', 'disposable_email_checker', 'trustpilot_service']}
    />
  );
}
