import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserSettings } from '@redux/actions/profileActions';
import { Layout, Menu, Space, Badge, Drawer, Modal, AutoComplete, Button } from 'antd';
import {
  StarOutlined,
  AppstoreOutlined,
  EnvironmentOutlined,
  ShoppingOutlined,
  MailOutlined,
  PhoneOutlined,
  GlobalOutlined,
  ApiOutlined,
  ClockCircleOutlined,
  MenuOutlined,
  DollarOutlined,
  CreditCardOutlined,
  LogoutOutlined,
  UserOutlined,
  ScheduleOutlined,
  QuestionCircleOutlined,
  QuestionOutlined,
  ReadOutlined,
  VideoCameraOutlined,
  TeamOutlined,
  CloseCircleOutlined,
  CustomerServiceOutlined,
  BugOutlined,
  FileTextOutlined,
  HistoryOutlined,
  LinkOutlined,
  SearchOutlined,
  ProductOutlined, UsergroupAddOutlined
} from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { checkIfNewService, SERVICES_DATA } from '@shared/data/services';
import { isEmpty } from 'lodash';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Avatar from 'react-avatar';
import config from 'react-global-configuration';

import { isCredibleUser } from '@utils/utils';
import { Logo, LogoMobile } from '@assets/icons';
import './Header.scss';
import FeatureRequestModal from '../Others/FeatureRequestModal';
import { useMedia } from '@hooks/useMedia';
import ThemeToggle from './ThemeToggle';
import Href from '@shared/Components/Href';
import {
  AirbnbLogo,
  AmazonLightLogo, AmazonDarkLogo,
  AppleDarkLogo, AppleLightLogo,
  BiggestbookLogo,
  BookingLogo,
  BootsLogo,
  CapterraLogo,
  EbayLogo,
  ExpediaLogo,
  FastbackgroundcheckLightLogo, FastbackgroundcheckDarkLogo,
  G2Logo,
  GetappLogo,
  GlassdoorLogo,
  GoogleLogo,
  HomedepotLogo,
  HotelsLogo,
  IndeedLogo,
  LinkedinLogo,
  MacysLogo,
  OfficeDepotLogo,
  ProducthuntLogo,
  RedditLogo,
  SamsclubLogo,
  ShopeeLogo,
  ShopifyLogo,
  TargetLogo,
  TescoLogo,
  TiktokDarkLogo, TiktokLightLogo,
  TripadvisorLogo,
  TrustpilotLogo,
  TwitterLogo,
  WalmartLogo, Webstaurantstore, WhitepagesLogo, YellowpagesLogo,
  YelpLogo,
  YoutubeLogo,
  ZillowLogo, BestBuyLogo, CostcoLogo, EtsyLogo, GraingerLogo, StaplesLogo, DoordashLogo
} from '@assets/icons/brand-logos/brand-logos';
import { useTheme } from '@context/ThemeContext';

const { Header } = Layout;
const notRoutes = new Set(['support', 'report', 'logout']);

const HeaderComponent = ({ auth }) => {
  const navigate = useNavigate();
  const { isDesktop } = useMedia();
  const dispatch = useDispatch();
  const { isDarkMode } = useTheme();

  const { profile = {}, authenticated = false } = auth;
  const { avatar, name, email, roles = [] } = profile;
  const isTenant = roles.includes('tenant');
  const isAdmin = roles.includes('admin');

  const { t, i18n } = useTranslation();

  const [featureRequestOpen, setFeatureRequestOpen] = useState(false);
  const [isHelpOpened, setIsHelpOpened] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');

  const { userSettings = { favorites: [] } } = useSelector(state => state.profileReducer);

  useEffect(() => {
    dispatch(fetchUserSettings());
  }, [dispatch]);

  const getParsedLocalStorage = (key, defaultValue = []) => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  };

  const starredServicesArray = userSettings?.favorites || [];
  const recentServicesArray = getParsedLocalStorage('recentServices');
  const recentNewServicesArray = [
    ...getParsedLocalStorage('recentNewServices'),
    window.location.pathname.split('/')[1],
  ];

  function showUserName(email) {
    if (email) {
      return email.split('@')[0];
    } else {
      return email;
    }
  }

  function onHelpClick() {
    if (isHelpOpened) {
      window.Chatra('closeChat');
    } else {
      window.Chatra('updateIntegrationData', { email });
      window.Chatra('openChat', true);
    }
    setIsHelpOpened(!isHelpOpened);
  }

  function handleLogout() {
    Modal.confirm({
      title: t('title.logOut'),
      content: t('title.areYouSure'),
      okText: t('action.yes'),
      okType: 'danger',
      cancelText: t('action.no'),
      onOk() {
        navigate('/logout');
      },
    });
  }

  function getBadgeCount(key, children) {
    if (checkIfNewService(key, recentNewServicesArray)) return 1;

    let result = 0;
    if (Array.isArray(children)) {
      children.forEach(({ key, children }) => {
        result += getBadgeCount(key ? key.split('?')[0] : key, children);
      });
    }

    return result;
  }

  const getIconByTitle = (title) => {
    return Object.values(SERVICES_DATA).find(service => service.label === title)?.icon || null;
  };

  function getItem(label, key, icon, children, type) {
    let translatedLabel = (icon || children || type) ? label : t(`title.${label}`, label);

    const formatedKey = key ? key.split('?')[0] : key;
    const badgeCount = getBadgeCount(formatedKey, children);
    const isNewService = checkIfNewService(formatedKey, recentNewServicesArray);

    if (isEmpty(children)) {
      if (key.startsWith('https://') || key.startsWith('http://')) {
        translatedLabel = <a href={key} target='_blank' rel='noopener noreferrer'>{translatedLabel}</a>;
      } else if (!notRoutes.has(key)) {
        const ln = i18n.language.split('-')[0];
        if (ln !== 'en') {
          key = key + (key.includes('?') ? '&' : '?') + `ln=${ln}`;
        }

        translatedLabel = <Href href={key.startsWith('/') ? key : `/${key}`}>{translatedLabel}</Href>;
      }
    }

    if (badgeCount || isNewService) {
      translatedLabel = <Badge
        title={isNewService ? 'New service' : `${badgeCount} new servic(es)`}
        size='small'
        dot={isNewService || null}
        count={isNewService ? null : badgeCount}
        offset={[isNewService ? 6 : badgeCount > 9 ? 20 : 12, 0]}
        overflowCount={9}
      >
        <span>{translatedLabel}&nbsp;</span>&nbsp;
      </Badge>;
    }

    if (type === 'group' && icon) {
      translatedLabel = <><span style={{ marginRight: 8 }}>{icon}</span>{translatedLabel}&nbsp;</>;
    }

    return {
      key,
      icon,
      children,
      items: children,
      label: translatedLabel,
      type,
      title: translatedLabel
    };
  }

  const profileMenu = [
    getItem(authenticated ? name || showUserName(email) : t('title.profile'), 'profile-items', <Avatar
      className='profile-avatar'
      src={avatar}
      email={!avatar ? email : null}
      name={!avatar && !email ? name : showUserName(email)}
      size='32'
      round={true}
    />, [
      getItem(t('title.profile'), 'profile', <UserOutlined />),
      ...(isTenant ? [] : [
        getItem(t('title.billingInformation'), 'billing-info', <CreditCardOutlined />),
      ]),
      getItem(t('title.invoices'), 'invoices', <FileTextOutlined />),
      getItem(t('title.logOut'), 'logout', <LogoutOutlined />)
    ])];

  const servicesMenu = [
    getItem(t('title.byBrand'), 'sources', null, [
      getItem('Airbnb', 'source-airbnb', <AirbnbLogo />, [
        getItem(SERVICES_DATA.airbnb_search_service.label, `${SERVICES_DATA.airbnb_search_service.key}?`, SERVICES_DATA.airbnb_search_service.icon),
        getItem(SERVICES_DATA.airbnb_reviews_service.label, `${SERVICES_DATA.airbnb_reviews_service.key}?`, SERVICES_DATA.airbnb_reviews_service.icon),
      ]),
      getItem('Amazon', 'source-amazon', isDarkMode ? <AmazonLightLogo /> : <AmazonDarkLogo />, [
        getItem(SERVICES_DATA.amazon_products_service.label, `${SERVICES_DATA.amazon_products_service.key}?`, SERVICES_DATA.amazon_products_service.icon),
        getItem(SERVICES_DATA.amazon_reviews_service.label, `${SERVICES_DATA.amazon_reviews_service.key}?`, SERVICES_DATA.amazon_reviews_service.icon),
      ]),
      getItem('Apple', 'source-apple', isDarkMode ? <AppleLightLogo /> : <AppleDarkLogo />, [
        getItem(SERVICES_DATA.appstore_reviews_service.label, `${SERVICES_DATA.appstore_reviews_service.key}?`, SERVICES_DATA.appstore_reviews_service.icon),
      ]),
      getItem('BiggestBook', 'source-biggestbook', <BiggestbookLogo />, [
        getItem(SERVICES_DATA.biggestbook_products_service.label, `${SERVICES_DATA.biggestbook_products_service.key}?`, SERVICES_DATA.biggestbook_products_service.icon),
      ]),
      getItem('Booking', 'source-booking', <BookingLogo />, [
        getItem(SERVICES_DATA.booking_search_service.label, `${SERVICES_DATA.booking_search_service.key}?`, SERVICES_DATA.booking_search_service.icon),
        getItem(SERVICES_DATA.booking_reviews_service.label, `${SERVICES_DATA.booking_reviews_service.key}?`, SERVICES_DATA.booking_reviews_service.icon),
        getItem(SERVICES_DATA.booking_prices_service.label, `${SERVICES_DATA.booking_prices_service.key}?`, SERVICES_DATA.booking_prices_service.icon),
      ]),
      getItem('Boots', 'source-boots', <BootsLogo />, [
        getItem(SERVICES_DATA.boots_products_service.label, `${SERVICES_DATA.boots_products_service.key}?`, SERVICES_DATA.boots_products_service.icon),
      ]),
      getItem('BestBuy', 'source-bestbuy', <BestBuyLogo />, [
        getItem(SERVICES_DATA.bestbuy_reviews_service.label, `${SERVICES_DATA.bestbuy_reviews_service.key}?`, SERVICES_DATA.bestbuy_reviews_service.icon),
      ]),
      getItem('Capterra', 'source-capterra', <CapterraLogo />, [
        getItem(SERVICES_DATA.capterra_reviews_service.label, `${SERVICES_DATA.capterra_reviews_service.key}?`, SERVICES_DATA.capterra_reviews_service.icon),
      ]),
      getItem('Costco', 'source-costco', <CostcoLogo />, [
        getItem(SERVICES_DATA.costco_products_service.label, `${SERVICES_DATA.costco_products_service.key}?`, SERVICES_DATA.costco_products_service.icon),
      ]),
      getItem('Doordash', 'source-doordash', <DoordashLogo />, [
        getItem(SERVICES_DATA.doordash_service.label, `${SERVICES_DATA.doordash_service.key}?`, SERVICES_DATA.doordash_service.icon),
      ]),
      getItem('eBay', 'source-ebay', <EbayLogo />, [
        getItem(SERVICES_DATA.ebay_products_service.label, `${SERVICES_DATA.ebay_products_service.key}?`, SERVICES_DATA.ebay_products_service.icon),
      ]),
      getItem('Expedia', 'source-expedia', <ExpediaLogo />, [
        getItem(SERVICES_DATA.expedia_search_service.label, `${SERVICES_DATA.expedia_search_service.key}?`, SERVICES_DATA.expedia_search_service.icon),
        getItem(SERVICES_DATA.expedia_reviews_service.label, `${SERVICES_DATA.expedia_reviews_service.key}?`, SERVICES_DATA.expedia_reviews_service.icon),
      ]),
      getItem('Etsy', 'source-etsy', <EtsyLogo />, [
        getItem(SERVICES_DATA.etsy_reviews_service.label, `${SERVICES_DATA.etsy_reviews_service.key}?`, SERVICES_DATA.etsy_reviews_service.icon),
        getItem(SERVICES_DATA.etsy_products_service.label, `${SERVICES_DATA.etsy_products_service.key}?`, SERVICES_DATA.etsy_products_service.icon),
      ]),
      getItem('Fastbackgroundcheck', 'source-fastbackgroundcheck', isDarkMode ? <FastbackgroundcheckLightLogo /> : <FastbackgroundcheckDarkLogo />, [
        getItem(SERVICES_DATA.fastbackgroundcheck_addresses_service.label, `${SERVICES_DATA.fastbackgroundcheck_addresses_service.key}?`, SERVICES_DATA.fastbackgroundcheck_addresses_service.icon),
      ]),
      getItem('G2', 'source-g2', <G2Logo />, [
        getItem(SERVICES_DATA.g2_reviews_service.label, `${SERVICES_DATA.g2_reviews_service.key}?`, SERVICES_DATA.g2_reviews_service.icon),
      ]),
      getItem('GetApp', 'source-getapp', <GetappLogo />, [
        getItem(SERVICES_DATA.getapp_reviews_service.label, `${SERVICES_DATA.getapp_reviews_service.key}?`, SERVICES_DATA.getapp_reviews_service.icon),
      ]),
      getItem('Glassdoor', 'source-glassdoor', <GlassdoorLogo />, [
        getItem(SERVICES_DATA.glassdoor_search_service.label, `${SERVICES_DATA.glassdoor_search_service.key}?`, SERVICES_DATA.glassdoor_search_service.icon),
        getItem(SERVICES_DATA.glassdoor_reviews_service.label, `${SERVICES_DATA.glassdoor_reviews_service.key}?`, SERVICES_DATA.glassdoor_reviews_service.icon),
      ]),
      getItem('Google', 'source-google', <GoogleLogo />, [
        getItem(SERVICES_DATA.google_maps_service.label, `${SERVICES_DATA.google_maps_service.key}?`, SERVICES_DATA.google_maps_service.icon),
        getItem(SERVICES_DATA.google_reviews_service.label, `${SERVICES_DATA.google_reviews_service.key}?`, SERVICES_DATA.google_reviews_service.icon),
        getItem(SERVICES_DATA.google_search_service.label, `${SERVICES_DATA.google_search_service.key}?`, SERVICES_DATA.google_search_service.icon),
        getItem(SERVICES_DATA.google_places_domain_search_service.label, `${SERVICES_DATA.google_places_domain_search_service.key}?`, SERVICES_DATA.google_places_domain_search_service.icon),
        getItem(SERVICES_DATA.google_maps_contributor_reviews_service.label, `${SERVICES_DATA.google_maps_contributor_reviews_service.key}?`, SERVICES_DATA.google_maps_contributor_reviews_service.icon),
        getItem(SERVICES_DATA.google_maps_photos_service.label, `${SERVICES_DATA.google_maps_photos_service.key}?`, SERVICES_DATA.google_maps_photos_service.icon),
        getItem(SERVICES_DATA.google_traffic_service.label, `${SERVICES_DATA.google_traffic_service.key}?`, SERVICES_DATA.google_traffic_service.icon),
        getItem(SERVICES_DATA.google_directory_places_service.label, `${SERVICES_DATA.google_directory_places_service.key}?`, SERVICES_DATA.google_directory_places_service.icon),
        getItem(SERVICES_DATA.google_search_news_service.label, `${SERVICES_DATA.google_search_news_service.key}?`, SERVICES_DATA.google_search_news_service.icon),
        getItem(SERVICES_DATA.google_search_images_service.label, `${SERVICES_DATA.google_search_images_service.key}?`, SERVICES_DATA.google_search_images_service.icon),
        getItem(SERVICES_DATA.google_search_videos_service.label, `${SERVICES_DATA.google_search_videos_service.key}?`, SERVICES_DATA.google_search_videos_service.icon),
        getItem(SERVICES_DATA.google_search_events_service.label, `${SERVICES_DATA.google_search_events_service.key}?`, SERVICES_DATA.google_search_events_service.icon),
        getItem(SERVICES_DATA.google_search_jobs_service.label, `${SERVICES_DATA.google_search_jobs_service.key}?`, SERVICES_DATA.google_search_jobs_service.icon),
        getItem(SERVICES_DATA.google_careers_service.label, `${SERVICES_DATA.google_careers_service.key}?`, SERVICES_DATA.google_careers_service.icon),
        getItem(SERVICES_DATA.google_search_shopping_service.label, `${SERVICES_DATA.google_search_shopping_service.key}?`, SERVICES_DATA.google_search_shopping_service.icon),
      ]),
      getItem('Grainger', 'source-grainger', <GraingerLogo />, [
        getItem(SERVICES_DATA.grainger_products_service.label, `${SERVICES_DATA.grainger_products_service.key}?`, SERVICES_DATA.grainger_products_service.icon),
      ]),
      getItem('Home Depot', 'source-homedepot', <HomedepotLogo />, [
        getItem(SERVICES_DATA.homedepot_reviews_service.label, `${SERVICES_DATA.homedepot_reviews_service.key}?`, SERVICES_DATA.homedepot_reviews_service.icon),
      ]),
      getItem('Hotels', 'source-hotels', <HotelsLogo />, [
        getItem(SERVICES_DATA.hotels_search_service.label, `${SERVICES_DATA.hotels_search_service.key}?`, SERVICES_DATA.hotels_search_service.icon),
        getItem(SERVICES_DATA.hotels_reviews_service.label, `${SERVICES_DATA.hotels_reviews_service.key}?`, SERVICES_DATA.hotels_reviews_service.icon),
      ]),
      getItem('Indeed', 'source-indeed', <IndeedLogo />, [
        getItem(SERVICES_DATA.indeed_search_service.label, `${SERVICES_DATA.indeed_search_service.key}?`, SERVICES_DATA.indeed_search_service.icon),
        getItem(SERVICES_DATA.indeed_reviews_service.label, `${SERVICES_DATA.indeed_reviews_service.key}?`, SERVICES_DATA.indeed_reviews_service.icon),
      ]),
      getItem('Linkedin', 'source-Linkedin', <LinkedinLogo />, [
        getItem(SERVICES_DATA.linkedin_companies_service.label, `${SERVICES_DATA.linkedin_companies_service.key}?`, SERVICES_DATA.linkedin_companies_service.icon),
        getItem(SERVICES_DATA.linkedin_profiles_service.label, `${SERVICES_DATA.linkedin_profiles_service.key}?`, SERVICES_DATA.linkedin_profiles_service.icon),
        getItem(SERVICES_DATA.linkedin_posts_service.label, `${SERVICES_DATA.linkedin_posts_service.key}?`, SERVICES_DATA.linkedin_posts_service.icon),
      ]),
      getItem('Macys', 'source-macys', <MacysLogo />, [
        getItem(SERVICES_DATA.macys_products_service.label, `${SERVICES_DATA.macys_products_service.key}?`, SERVICES_DATA.macys_products_service.icon),
      ]),
      getItem('Office Depot', 'source-officedepot', <OfficeDepotLogo />, [
        getItem(SERVICES_DATA.officedepot_products_service.label, `${SERVICES_DATA.officedepot_products_service.key}?`, SERVICES_DATA.officedepot_products_service.icon),
      ]),
      getItem('Product Hunt', 'source-producthunt', <ProducthuntLogo />, [
        getItem(SERVICES_DATA.producthunt_reviews_service.label, `${SERVICES_DATA.producthunt_reviews_service.key}?`, SERVICES_DATA.producthunt_reviews_service.icon),
      ]),
      getItem('Reddit', 'source-reddit', <RedditLogo />, [
        getItem(SERVICES_DATA.reddit_search_service.label, `${SERVICES_DATA.reddit_search_service.key}?`, SERVICES_DATA.reddit_search_service.icon),
        getItem(SERVICES_DATA.reddit_comments_service.label, `${SERVICES_DATA.reddit_comments_service.key}?`, SERVICES_DATA.reddit_comments_service.icon),
      ]),
      getItem('Sams Club', 'source-samsclub', <SamsclubLogo />, [
        getItem(SERVICES_DATA.samsclub_products_service.label, `${SERVICES_DATA.samsclub_products_service.key}?`, SERVICES_DATA.samsclub_products_service.icon),
      ]),
      getItem('Staples', 'source-staples', <StaplesLogo />, [
        getItem(SERVICES_DATA.staples_products_service.label, `${SERVICES_DATA.staples_products_service.key}?`, SERVICES_DATA.staples_products_service.icon),
      ]),
      getItem('Shopee', 'source-shopee', <ShopeeLogo />, [
        getItem(SERVICES_DATA.shopee_reviews_service.label, `${SERVICES_DATA.shopee_reviews_service.key}?`, SERVICES_DATA.shopee_reviews_service.icon),
      ]),
      getItem('Shopify', 'source-shopify', <ShopifyLogo />, [
        getItem(SERVICES_DATA.shopify_products_service.label, `${SERVICES_DATA.shopify_products_service.key}?`, SERVICES_DATA.shopify_products_service.icon),
      ]),
      getItem('Target', 'source-target', <TargetLogo />, [
        getItem(SERVICES_DATA.target_reviews_service.label, `${SERVICES_DATA.target_reviews_service.key}?`, SERVICES_DATA.target_reviews_service.icon),
      ]),
      getItem('Tesco', 'source-tesco', <TescoLogo />, [
        getItem(SERVICES_DATA.tesco_products_service.label, `${SERVICES_DATA.tesco_products_service.key}?`, SERVICES_DATA.tesco_products_service.icon),
      ]),
      getItem('TikTok', 'source-tiktok', isDarkMode ? <TiktokLightLogo /> : <TiktokDarkLogo />, [
        getItem(SERVICES_DATA.tiktok_profiles_service.label, `${SERVICES_DATA.tiktok_profiles_service.key}?`, SERVICES_DATA.tiktok_profiles_service.icon),
      ]),
      getItem('Tripadvisor', 'source-tripadvisor', <TripadvisorLogo />, [
        getItem(SERVICES_DATA.tripadvisor_search_service.label, `${SERVICES_DATA.tripadvisor_search_service.key}?`, SERVICES_DATA.tripadvisor_search_service.icon),
        getItem(SERVICES_DATA.tripadvisor_reviews_service.label, `${SERVICES_DATA.tripadvisor_reviews_service.key}?`, SERVICES_DATA.tripadvisor_reviews_service.icon),
      ]),
      getItem('Trustpilot', 'source-trustpilot', <TrustpilotLogo />, [
        getItem(SERVICES_DATA.trustpilot_service.label, `${SERVICES_DATA.trustpilot_service.key}?`, SERVICES_DATA.trustpilot_service.icon),
        getItem(SERVICES_DATA.trustpilot_search_service.label, `${SERVICES_DATA.trustpilot_search_service.key}?`, SERVICES_DATA.trustpilot_search_service.icon),
        getItem(SERVICES_DATA.trustpilot_reviews_service.label, `${SERVICES_DATA.trustpilot_reviews_service.key}?`, SERVICES_DATA.trustpilot_reviews_service.icon),
      ]),
      getItem('Twitter', 'source-twitter', <TwitterLogo />, [
        getItem(SERVICES_DATA.twitter_profiles_service.label, `${SERVICES_DATA.twitter_profiles_service.key}?`, SERVICES_DATA.twitter_profiles_service.icon),
      ]),
      getItem('Walmart', 'source-walmart', <WalmartLogo />, [
        getItem(SERVICES_DATA.walmart_reviews_service.label, `${SERVICES_DATA.walmart_reviews_service.key}?`, SERVICES_DATA.walmart_reviews_service.icon),
      ]),
      getItem('Webstaurantstore', 'source-webstaurantstore', <Webstaurantstore />, [
        getItem(SERVICES_DATA.webstaurantstore_products_service.label, `${SERVICES_DATA.webstaurantstore_products_service.key}?`, SERVICES_DATA.webstaurantstore_products_service.icon),
      ]),
      getItem('Whitepages', 'source-whitepages', <WhitepagesLogo />, [
        getItem(SERVICES_DATA.whitepages_phones_service.label, `${SERVICES_DATA.whitepages_phones_service.key}?`, SERVICES_DATA.whitepages_phones_service.icon),
        getItem(SERVICES_DATA.whitepages_addresses_service.label, `${SERVICES_DATA.whitepages_addresses_service.key}?`, SERVICES_DATA.whitepages_addresses_service.icon),
      ]),
      getItem('Yellow Pages', 'yellow-pages', <YellowpagesLogo />, [
        getItem(SERVICES_DATA.yellowpages_service.label, `${SERVICES_DATA.yellowpages_service.key}?`, SERVICES_DATA.yellowpages_service.icon),
      ]),
      getItem('Y.E.L.P', 'source-yelp', <YelpLogo />, [
        getItem(SERVICES_DATA.yelp_service.label, `${SERVICES_DATA.yelp_service.key}?`, SERVICES_DATA.yelp_service.icon),
        getItem(SERVICES_DATA.yelp_reviews_service.label, `${SERVICES_DATA.yelp_reviews_service.key}?`, SERVICES_DATA.yelp_reviews_service.icon),
        getItem(SERVICES_DATA.yelp_photos_service.label, `${SERVICES_DATA.yelp_photos_service.key}?`, SERVICES_DATA.yelp_photos_service.icon),
      ]),
      getItem('Youtube', 'source-youtube', <YoutubeLogo />, [
        getItem(SERVICES_DATA.youtube_channels_service.label, `${SERVICES_DATA.youtube_channels_service.key}?`, SERVICES_DATA.youtube_channels_service.icon),
        getItem(SERVICES_DATA.youtube_comments_service.label, `${SERVICES_DATA.youtube_comments_service.key}?`, SERVICES_DATA.youtube_comments_service.icon),
        getItem(SERVICES_DATA.youtube_search_service.label, `${SERVICES_DATA.youtube_search_service.key}?`, SERVICES_DATA.youtube_search_service.icon),
      ]),
      getItem('Zillow', 'source-zillow', <ZillowLogo />, [
        getItem(SERVICES_DATA.zillow_search_service.label, `${SERVICES_DATA.zillow_search_service.key}?`, SERVICES_DATA.zillow_search_service.icon),
      ]),
    ]),
    getItem(t('title.byType'), 'types', null, [
      getItem(t('title.poiRelated'), 'pois', <EnvironmentOutlined />, [
        getItem(SERVICES_DATA.google_maps_service.label, SERVICES_DATA.google_maps_service.key, SERVICES_DATA.google_maps_service.icon),
        getItem(SERVICES_DATA.google_places_domain_search_service.label, SERVICES_DATA.google_places_domain_search_service.key, SERVICES_DATA.google_places_domain_search_service.icon),
        getItem(SERVICES_DATA.tripadvisor_search_service.label, SERVICES_DATA.tripadvisor_search_service.key, SERVICES_DATA.tripadvisor_search_service.icon),
        getItem(SERVICES_DATA.trustpilot_search_service.label, SERVICES_DATA.trustpilot_search_service.key, SERVICES_DATA.trustpilot_search_service.icon),
        getItem(SERVICES_DATA.trustpilot_service.label, SERVICES_DATA.trustpilot_service.key, SERVICES_DATA.trustpilot_service.icon),
        getItem(SERVICES_DATA.linkedin_companies_service.label, SERVICES_DATA.linkedin_companies_service.key, SERVICES_DATA.linkedin_companies_service.icon),
        getItem(SERVICES_DATA.whitepages_addresses_service.label, SERVICES_DATA.whitepages_addresses_service.key, SERVICES_DATA.whitepages_addresses_service.icon),
        getItem(SERVICES_DATA.fastbackgroundcheck_addresses_service.label, SERVICES_DATA.fastbackgroundcheck_addresses_service.key, SERVICES_DATA.fastbackgroundcheck_addresses_service.icon),
        getItem(SERVICES_DATA.yellowpages_service.label, SERVICES_DATA.yellowpages_service.key, SERVICES_DATA.yellowpages_service.icon),
        getItem(SERVICES_DATA.yelp_service.label, SERVICES_DATA.yelp_service.key, SERVICES_DATA.yelp_service.icon),
        getItem(SERVICES_DATA.company_insights_service.label, SERVICES_DATA.company_insights_service.key, SERVICES_DATA.company_insights_service.icon),
        getItem(SERVICES_DATA.similarweb_service.label, SERVICES_DATA.similarweb_service.key, SERVICES_DATA.similarweb_service.icon),
        getItem(SERVICES_DATA.business_directory_service.label, SERVICES_DATA.business_directory_service.key, SERVICES_DATA.business_directory_service.icon),
        getItem('Hotels', 'hotels', null, [
          getItem(SERVICES_DATA.airbnb_search_service.label, SERVICES_DATA.airbnb_search_service.key, SERVICES_DATA.airbnb_search_service.icon),
          getItem(SERVICES_DATA.booking_search_service.label, SERVICES_DATA.booking_search_service.key, SERVICES_DATA.booking_search_service.icon),
          getItem(SERVICES_DATA.booking_prices_service.label, SERVICES_DATA.booking_prices_service.key, SERVICES_DATA.booking_prices_service.icon),
          getItem(SERVICES_DATA.expedia_search_service.label, SERVICES_DATA.expedia_search_service.key, SERVICES_DATA.expedia_search_service.icon),
          getItem(SERVICES_DATA.hotels_search_service.label, SERVICES_DATA.hotels_search_service.key, SERVICES_DATA.hotels_search_service.icon),
        ]),
      ]),
      getItem(t('title.reviewsRelated'), 'reviews', <StarOutlined />, [
        getItem(SERVICES_DATA.amazon_reviews_service.label, SERVICES_DATA.amazon_reviews_service.key, SERVICES_DATA.amazon_reviews_service.icon),
        getItem(SERVICES_DATA.appstore_reviews_service.label, SERVICES_DATA.appstore_reviews_service.key, SERVICES_DATA.appstore_reviews_service.icon),
        getItem(SERVICES_DATA.airbnb_reviews_service.label, SERVICES_DATA.airbnb_reviews_service.key, SERVICES_DATA.airbnb_reviews_service.icon),
        getItem(SERVICES_DATA.booking_reviews_service.label, SERVICES_DATA.booking_reviews_service.key, SERVICES_DATA.booking_reviews_service.icon),
        getItem(SERVICES_DATA.bestbuy_reviews_service.label, SERVICES_DATA.bestbuy_reviews_service.key, SERVICES_DATA.bestbuy_reviews_service.icon),
        getItem(SERVICES_DATA.capterra_reviews_service.label, SERVICES_DATA.capterra_reviews_service.key, SERVICES_DATA.capterra_reviews_service.icon),
        getItem(SERVICES_DATA.expedia_reviews_service.label, SERVICES_DATA.expedia_reviews_service.key, SERVICES_DATA.expedia_reviews_service.icon),
        getItem(SERVICES_DATA.g2_reviews_service.label, SERVICES_DATA.g2_reviews_service.key, SERVICES_DATA.g2_reviews_service.icon),
        getItem(SERVICES_DATA.getapp_reviews_service.label, SERVICES_DATA.getapp_reviews_service.key, SERVICES_DATA.getapp_reviews_service.icon),
        getItem(SERVICES_DATA.glassdoor_reviews_service.label, SERVICES_DATA.glassdoor_reviews_service.key, SERVICES_DATA.glassdoor_reviews_service.icon),
        getItem(SERVICES_DATA.google_reviews_service.label, SERVICES_DATA.google_reviews_service.key, SERVICES_DATA.google_reviews_service.icon),
        getItem(SERVICES_DATA.google_maps_contributor_reviews_service.label, SERVICES_DATA.google_maps_contributor_reviews_service.key, SERVICES_DATA.google_maps_contributor_reviews_service.icon),
        getItem(SERVICES_DATA.google_play_reviews_service.label, SERVICES_DATA.google_play_reviews_service.key, SERVICES_DATA.google_play_reviews_service.icon),
        getItem(SERVICES_DATA.google_shopping_reviews_service.label, SERVICES_DATA.google_shopping_reviews_service.key, SERVICES_DATA.google_shopping_reviews_service.icon),
        getItem(SERVICES_DATA.homedepot_reviews_service.label, SERVICES_DATA.homedepot_reviews_service.key, SERVICES_DATA.homedepot_reviews_service.icon),
        getItem(SERVICES_DATA.hotels_reviews_service.label, SERVICES_DATA.hotels_reviews_service.key, SERVICES_DATA.hotels_reviews_service.icon),
        getItem(SERVICES_DATA.etsy_reviews_service.label, SERVICES_DATA.etsy_reviews_service.key, SERVICES_DATA.etsy_reviews_service.icon),
        getItem(SERVICES_DATA.indeed_reviews_service.label, SERVICES_DATA.indeed_reviews_service.key, SERVICES_DATA.indeed_reviews_service.icon),
        getItem(SERVICES_DATA.producthunt_reviews_service.label, SERVICES_DATA.producthunt_reviews_service.key, SERVICES_DATA.producthunt_reviews_service.icon),
        getItem(SERVICES_DATA.reddit_comments_service.label, SERVICES_DATA.reddit_comments_service.key, SERVICES_DATA.reddit_comments_service.icon),
        getItem(SERVICES_DATA.shopee_reviews_service.label, SERVICES_DATA.shopee_reviews_service.key, SERVICES_DATA.shopee_reviews_service.icon),
        getItem(SERVICES_DATA.target_reviews_service.label, SERVICES_DATA.target_reviews_service.key, SERVICES_DATA.target_reviews_service.icon),
        getItem(SERVICES_DATA.tripadvisor_reviews_service.label, SERVICES_DATA.tripadvisor_reviews_service.key, SERVICES_DATA.tripadvisor_reviews_service.icon),
        getItem(SERVICES_DATA.trustpilot_reviews_service.label, SERVICES_DATA.trustpilot_reviews_service.key, SERVICES_DATA.trustpilot_reviews_service.icon),
        getItem(SERVICES_DATA.walmart_reviews_service.label, SERVICES_DATA.walmart_reviews_service.key, SERVICES_DATA.walmart_reviews_service.icon),
        getItem(SERVICES_DATA.youtube_comments_service.label, SERVICES_DATA.youtube_comments_service.key, SERVICES_DATA.youtube_comments_service.icon),
        getItem(SERVICES_DATA.yelp_reviews_service.label, SERVICES_DATA.yelp_reviews_service.key, SERVICES_DATA.yelp_reviews_service.icon),
      ]),
      getItem(t('title.productsRelated'), 'products', <ShoppingOutlined />, [
        getItem(SERVICES_DATA.amazon_products_service.label, SERVICES_DATA.amazon_products_service.key, SERVICES_DATA.amazon_products_service.icon),
        getItem(SERVICES_DATA.biggestbook_products_service.label, SERVICES_DATA.biggestbook_products_service.key, SERVICES_DATA.biggestbook_products_service.icon),
        getItem(SERVICES_DATA.boots_products_service.label, SERVICES_DATA.boots_products_service.key, SERVICES_DATA.boots_products_service.icon),
        getItem(SERVICES_DATA.ebay_products_service.label, SERVICES_DATA.ebay_products_service.key, SERVICES_DATA.ebay_products_service.icon),
        getItem(SERVICES_DATA.macys_products_service.label, SERVICES_DATA.macys_products_service.key, SERVICES_DATA.macys_products_service.icon),
        getItem(SERVICES_DATA.staples_products_service.label, SERVICES_DATA.staples_products_service.key, SERVICES_DATA.staples_products_service.icon),
        getItem(SERVICES_DATA.officedepot_products_service.label, SERVICES_DATA.officedepot_products_service.key, SERVICES_DATA.officedepot_products_service.icon),
        getItem(SERVICES_DATA.samsclub_products_service.label, SERVICES_DATA.samsclub_products_service.key, SERVICES_DATA.samsclub_products_service.icon),
        getItem(SERVICES_DATA.costco_products_service.label, SERVICES_DATA.costco_products_service.key, SERVICES_DATA.costco_products_service.icon),
        getItem(SERVICES_DATA.webstaurantstore_products_service.label, SERVICES_DATA.webstaurantstore_products_service.key, SERVICES_DATA.webstaurantstore_products_service.icon),
        getItem(SERVICES_DATA.google_search_shopping_service.label, SERVICES_DATA.google_search_shopping_service.key, SERVICES_DATA.google_search_shopping_service.icon),
        getItem(SERVICES_DATA.shopify_products_service.label, SERVICES_DATA.shopify_products_service.key, SERVICES_DATA.shopify_products_service.icon),
        getItem(SERVICES_DATA.etsy_products_service.label, SERVICES_DATA.etsy_products_service.key, SERVICES_DATA.etsy_products_service.icon),
        getItem(SERVICES_DATA.tesco_products_service.label, SERVICES_DATA.tesco_products_service.key, SERVICES_DATA.tesco_products_service.icon),
        getItem(SERVICES_DATA.grainger_products_service.label, SERVICES_DATA.grainger_products_service.key, SERVICES_DATA.grainger_products_service.icon),
      ]),
      getItem(t('title.emailRelated'), 'emails', <MailOutlined />, [
        getItem(SERVICES_DATA.emails_scraper_service.label, SERVICES_DATA.emails_scraper_service.key, SERVICES_DATA.emails_scraper_service.icon),
        getItem(SERVICES_DATA.contacts_finder_service.label, SERVICES_DATA.contacts_finder_service.key, SERVICES_DATA.contacts_finder_service.icon),
        getItem(t('action.check'), null, null, [
          getItem(SERVICES_DATA.emails_validator_service.label, SERVICES_DATA.emails_validator_service.key, SERVICES_DATA.emails_validator_service.icon),
          getItem(SERVICES_DATA.disposable_email_checker_service.label, SERVICES_DATA.disposable_email_checker_service.key, SERVICES_DATA.disposable_email_checker_service.icon),
        ], 'group'),
        getItem(SERVICES_DATA.email_finder_service.label, SERVICES_DATA.email_finder_service.key, SERVICES_DATA.email_finder_service.icon),
      ]),
      getItem(t('title.phoneRelated'), 'phones', <PhoneOutlined />, [
        getItem(SERVICES_DATA.phones_enricher_service.label, SERVICES_DATA.phones_enricher_service.key, SERVICES_DATA.phones_enricher_service.icon),
        getItem(SERVICES_DATA.whitepages_phones_service.label, SERVICES_DATA.whitepages_phones_service.key, SERVICES_DATA.whitepages_phones_service.icon),
        // getItem(t('action.check'), null, null, [
        // getItem(SERVICES_DATA.whatsapp_checker_service.label, SERVICES_DATA.whatsapp_checker_service.key),
        // getItem('iMessage Checker', 'imessage-checker'),
        // getItem('Telegram Checker', 'telegram-checker'),
        // getItem('WeChat Checker', 'wechat-checker'),
        // ], 'group'),
        // getItem(t('action.send', 'Send'), null, null, [
        //   getItem('WhatsApp Sender', 'whatsapp-sender'),
        //   getItem('iMessage Sender', 'imessage-sender'),
        // ], 'group'),
      ]),
      getItem(t('title.domainRelated'), 'domains', <GlobalOutlined />, [
        getItem(SERVICES_DATA.emails_scraper_service.label, `${SERVICES_DATA.emails_scraper_service.key}?tab=domain`, SERVICES_DATA.emails_scraper_service.icon),
        getItem(SERVICES_DATA.contacts_finder_service.label, `${SERVICES_DATA.contacts_finder_service.key}?tab=domain`, SERVICES_DATA.contacts_finder_service.icon),
        getItem(SERVICES_DATA.company_insights_service.label, `${SERVICES_DATA.company_insights_service.key}?tab=domain`, SERVICES_DATA.company_insights_service.icon),
        getItem(SERVICES_DATA.similarweb_service.label, `${SERVICES_DATA.similarweb_service.key}?tab=domain`, SERVICES_DATA.similarweb_service.icon),
        getItem(SERVICES_DATA.google_places_domain_search_service.label, `${SERVICES_DATA.google_places_domain_search_service.key}?tab=domain`, SERVICES_DATA.google_places_domain_search_service.icon),
        getItem(SERVICES_DATA.trustpilot_service.label, `${SERVICES_DATA.trustpilot_service.key}?type=domain`, SERVICES_DATA.trustpilot_service.icon),
      ]),
    ]),
    getItem(t('title.byUseCase'), 'usecases', null, [
      getItem(t('title.leadGeneration'), 'usecases-lead', <UsergroupAddOutlined  />, [
        getItem(SERVICES_DATA.google_maps_service.label, `${SERVICES_DATA.google_maps_service.key}?tab=lead`, SERVICES_DATA.google_maps_service.icon),
        getItem(SERVICES_DATA.contacts_finder_service.label, `${SERVICES_DATA.contacts_finder_service.key}?tab=lead`, SERVICES_DATA.contacts_finder_service.icon),
        getItem(SERVICES_DATA.emails_scraper_service.label, `${SERVICES_DATA.emails_scraper_service.key}?tab=lead`, SERVICES_DATA.emails_scraper_service.icon),
        getItem(SERVICES_DATA.email_finder_service.label, `${SERVICES_DATA.email_finder_service.key}?tab=lead`, SERVICES_DATA.email_finder_service.icon),
        getItem(SERVICES_DATA.emails_validator_service.label, `${SERVICES_DATA.emails_validator_service.key}?tab=lead`, SERVICES_DATA.emails_validator_service.icon),
        getItem(SERVICES_DATA.disposable_email_checker_service.label, `${SERVICES_DATA.disposable_email_checker_service.key}?tab=lead`, SERVICES_DATA.disposable_email_checker_service.icon),
      ]),
      getItem(t('title.reputationManagement'), 'usecases-rep', <StarOutlined  />, [
        getItem(SERVICES_DATA.amazon_reviews_service.label, `${SERVICES_DATA.amazon_reviews_service.key}?tab=rep`, SERVICES_DATA.amazon_reviews_service.icon),
        getItem(SERVICES_DATA.appstore_reviews_service.label, `${SERVICES_DATA.appstore_reviews_service.key}?tab=rep`, SERVICES_DATA.appstore_reviews_service.icon),
        getItem(SERVICES_DATA.booking_reviews_service.label, `${SERVICES_DATA.booking_reviews_service.key}?tab=rep`, SERVICES_DATA.booking_reviews_service.icon),
        getItem(SERVICES_DATA.capterra_reviews_service.label, `${SERVICES_DATA.capterra_reviews_service.key}?tab=rep`, SERVICES_DATA.capterra_reviews_service.icon),
        getItem(SERVICES_DATA.google_reviews_service.label, `${SERVICES_DATA.google_reviews_service.key}?tab=rep`, SERVICES_DATA.google_reviews_service.icon),
        getItem(SERVICES_DATA.google_maps_contributor_reviews_service.label, `${SERVICES_DATA.google_maps_contributor_reviews_service.key}?tab=rep`, SERVICES_DATA.google_maps_contributor_reviews_service.icon),
        getItem(SERVICES_DATA.google_play_reviews_service.label, `${SERVICES_DATA.google_play_reviews_service.key}?tab=rep`, SERVICES_DATA.google_play_reviews_service.icon),
        getItem(SERVICES_DATA.google_shopping_reviews_service.label, `${SERVICES_DATA.google_shopping_reviews_service.key}?tab=rep`, SERVICES_DATA.google_shopping_reviews_service.icon),
        getItem(SERVICES_DATA.hotels_reviews_service.label, `${SERVICES_DATA.hotels_reviews_service.key}?tab=rep`, SERVICES_DATA.hotels_reviews_service.icon),
        getItem(SERVICES_DATA.producthunt_reviews_service.label, `${SERVICES_DATA.producthunt_reviews_service.key}?tab=rep`, SERVICES_DATA.producthunt_reviews_service.icon),
        getItem(SERVICES_DATA.shopee_reviews_service.label, `${SERVICES_DATA.shopee_reviews_service.key}?tab=rep`, SERVICES_DATA.shopee_reviews_service.icon),
        getItem(SERVICES_DATA.tripadvisor_reviews_service.label, `${SERVICES_DATA.tripadvisor_reviews_service.key}?tab=rep`, SERVICES_DATA.tripadvisor_reviews_service.icon),
        getItem(SERVICES_DATA.trustpilot_reviews_service.label, `${SERVICES_DATA.trustpilot_reviews_service.key}?tab=rep`, SERVICES_DATA.trustpilot_reviews_service.icon),
        getItem(SERVICES_DATA.youtube_comments_service.label, `${SERVICES_DATA.youtube_comments_service.key}?tab=rep`, SERVICES_DATA.youtube_comments_service.icon),
        getItem(SERVICES_DATA.yelp_reviews_service.label, `${SERVICES_DATA.yelp_reviews_service.key}?tab=rep`, SERVICES_DATA.yelp_reviews_service.icon),
      ]),
      getItem(t('title.locationIntelligence'), 'usecases-intel', <EnvironmentOutlined  />, [
        getItem(SERVICES_DATA.google_maps_service.label, `${SERVICES_DATA.google_maps_service.key}?tab=intel`, SERVICES_DATA.google_maps_service.icon),
        getItem(SERVICES_DATA.google_places_domain_search_service.label, `${SERVICES_DATA.google_places_domain_search_service.key}?tab=intel`, SERVICES_DATA.google_places_domain_search_service.icon),
        getItem(SERVICES_DATA.yelp_service.label, `${SERVICES_DATA.yelp_service.key}?tab=intel`, SERVICES_DATA.yelp_service.icon),
        getItem(SERVICES_DATA.yellowpages_service.label, `${SERVICES_DATA.yellowpages_service.key}?tab=intel`, SERVICES_DATA.yellowpages_service.icon),
      ]),
    ]),
    getItem(t('title.otherServices'), 'others', null, [
      getItem(SERVICES_DATA.universal_scraper_service.label, SERVICES_DATA.universal_scraper_service.key, SERVICES_DATA.universal_scraper_service.icon),
      getItem(SERVICES_DATA.screenshoter_service.label, SERVICES_DATA.screenshoter_service.key, SERVICES_DATA.screenshoter_service.icon),
      getItem('HubSpot', 'hubspot-group', null, [
        getItem(SERVICES_DATA.hubspot_contact_emails_finder_service.label, SERVICES_DATA.hubspot_contact_emails_finder_service.key, SERVICES_DATA.hubspot_contact_emails_finder_service.icon),
        getItem(SERVICES_DATA.hubspot_company_contacts_finder_service.label, SERVICES_DATA.hubspot_company_contacts_finder_service.key, SERVICES_DATA.hubspot_company_contacts_finder_service.icon),
      ], 'group'),
      getItem('Geocoding', 'geocoding-group', null, [
        getItem(SERVICES_DATA.reverse_geocoding_service.label, SERVICES_DATA.reverse_geocoding_service.key, SERVICES_DATA.reverse_geocoding_service.icon),
        getItem(SERVICES_DATA.geocoding_service.label, SERVICES_DATA.geocoding_service.key, SERVICES_DATA.geocoding_service.icon),
      ], 'group'),
    ]),
    ...(isAdmin ? [
      getItem('Internal Services', 'internal', null, [
        getItem('Places DB Search', 'places-search'),
        getItem('Places DB Update', 'places-update'),
        getItem('Domain Contacts Finder', 'domain-contacts-finder'),
      ])
    ] : []),
  ];

  const favoritesAndRecentServices = [
    !isEmpty(starredServicesArray) && getItem(t('title.favorites'), 'services.favorites', <StarOutlined />,
      [...starredServicesArray.map((service) => {
        const { title, href } = service;
        const icon = getIconByTitle(title);
        return getItem(title, href, icon);
      }),
      ]),
    !isEmpty(recentServicesArray) && getItem(t('title.recentlyVisited'), 'recently-visited', <ClockCircleOutlined />, [
      ...recentServicesArray.slice().reverse().map((service) => {
        const { title, href } = service;
        const icon = getIconByTitle(title);
        return getItem(title, href + '?tab=recent', icon);
      }),
    ]),
  ];

  const apiMenu = [
    getItem(t('title.apiDocs'), 'api-docs', <FileTextOutlined />),
    getItem(t('title.apiUsage'), 'api-usage', <HistoryOutlined />),
    getItem(t('title.integrations'), 'integrations', <LinkOutlined />),
  ];

  const helpMenu = [
    getItem('F.A.Q.', config.get('faqUrl'), <QuestionOutlined />),
    getItem(t('title.tutorials'), config.get('tutorialsUrl'), <ReadOutlined />),
    getItem(t('title.videos'), config.get('videosUrl'), <VideoCameraOutlined />),
    getItem(t('title.askCommunity'), config.get('communityUrl'), <TeamOutlined />),
    getItem(t('title.support'), 'support', isHelpOpened ? <CloseCircleOutlined /> : <CustomerServiceOutlined />),
    ...(isCredibleUser() ? [getItem(t('title.schedule'), 'https://outscraper.com/support/#schedule', <PhoneOutlined />)] : []),
    getItem(t('title.reportProblem'), 'report', <BugOutlined />),
    getItem(t('title.pricing'), config.get('pricingUrl'), <DollarOutlined />),
  ];

  const rootMenu = [
    getItem(t('title.services'), 'services', <AppstoreOutlined />, servicesMenu),
    getItem(t('title.tasks'), 'tasks', <ScheduleOutlined />),
    getItem('API' + ' & ' + t('title.integrations'), 'api-integrations', <ApiOutlined />, apiMenu),
    getItem(t('title.help'), 'help', <QuestionCircleOutlined />, helpMenu)
  ];

  const [menuStack, setMenuStack] = useState([getItem('Main Menu', 'root', null, rootMenu)]);
  const currentMenu = menuStack[menuStack.length - 1];

  const handleSearch = useCallback((value) => {
    setSearchTerm(value);
  }, []);

  const translateServicesData = (data) => {
    return data.map(item => {
      const translatedLabel = i18n.exists(`title.${item.label}`)
        ? t(`title.${item.label}`)
        : item.label;
      return {
        ...item,
        label: translatedLabel,
        children: item.children ? translateServicesData(item.children) : undefined,
        icon: getIconByTitle(item.label)
      };
    });
  };

  const filteredItems = useMemo(() => {
    const translatedData = translateServicesData(Object.values(SERVICES_DATA));
    return translatedData.filter((value) => value.label.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [searchTerm]);

  const handleHeaderMenuClick = (menuKey) => {
    const selectedMenu = rootMenu.find((item) => item.key === menuKey);
    if (selectedMenu?.items?.length > 0) {
      setMenuStack([getItem('Main Menu', 'root', null, rootMenu), selectedMenu]);
      setDrawerOpen(true);
    } else {
      setDrawerOpen(false);
    }
  };

  const goBack = () => {
    if (searchTerm) return;
    setMenuStack((prev) => {
      if (prev.length > 1) {
        return prev.slice(0, -1);
      } else {
        return [getItem('Main Menu', 'root', null, rootMenu)];
      }
    });
  };

  const handleMenuClick = ({ key }) => {
    const selectedItem = currentMenu.items.find((item) => item.key === key);

    if (selectedItem?.items?.length > 0) {
      setMenuStack((prev) => [...prev, selectedItem]);
    } else {
      if (key.startsWith('https://') || key.startsWith('http://')) {
        window.open(key, '_blank', 'noopener,noreferrer');
      } else if (key === 'support') {
        onHelpClick();
      } else if (key === 'report') {
        setFeatureRequestOpen((prev) => !prev);
      } else if (key === 'logout') {
        handleLogout();
      } else {
        navigate(key);
      }
      setSearchTerm('');
      setDrawerOpen(false);
    }
  };

  const drawerTitle = menuStack.length > 1 ?
    <Space align='center' className='drawer-title' onClick={goBack}>
      <span className={`back-icon ${searchTerm ? 'disabled' : ''}`}> <FontAwesomeIcon icon={faChevronLeft} /> </span>
      <h4 className='h4 bold'>{currentMenu.label}</h4>
    </Space> : <h4 className='h4 bold'>Menu</h4>;

  const accountMenu = () =>
    <Menu className='account-menu' theme={isDesktop ? 'dark' : 'light'} mode={isDesktop ? 'horizontal' : 'inline'} items={profileMenu} onClick={handleMenuClick} />;

  return (
    <Header theme='light' className='header'>
      <Link to='/' className='logo'>
        {isDesktop ? <Logo /> : <LogoMobile />}
      </Link>
      {isDesktop && (
        <Space size={32} className='menu'>
          {rootMenu.map((item) => (
            item.children ? (
              <span className='menu-item' key={item.key} onClick={() => handleHeaderMenuClick(item.key)}>
                {item.key === 'services' && <ProductOutlined className='mr-8' />}
                {item.title}
              </span>)
              : <span key={item.key}>{item.title}</span>
          ))}
        </Space>
      )}
      <Space className='right-controls' >
        <Space className='theme-switcher' size={0}>
          {isDesktop && (<ThemeToggle />)}
        </Space>
        {!isDesktop && (
          <Button type='text' className='burger-menu' onClick={() => setDrawerOpen(true)}>
            <MenuOutlined />
          </Button>
        )}
      </Space>
      <Drawer
        title={drawerTitle}
        placement={isDesktop ? 'left' : 'right'}
        onClose={() => setDrawerOpen(false)}
        open={isDrawerOpen}
      >
        <Space direction='vertical' className='w-100'>
          {!isDesktop && (currentMenu.key === 'root') && accountMenu()}
          {currentMenu.key === 'services' &&
            <AutoComplete
              variant='borderless'
              className='w-100'
              onChange={handleSearch}
              options={filteredItems.map(item => ({
                value: item.label,
                key: item.key,
                label: (
                  <div className='services-search-option'>
                    {item.icon}
                    <span className='option-label'>{item.label}</span>
                  </div>
                )
              }))}
              placeholder={t('title.search')}
              onSelect={(value, option) => handleMenuClick({ key: option.key })}
              prefix={<SearchOutlined />}
              value={searchTerm}
              allowClear
              onClear={() => setSearchTerm('')}
            />
          }
          <Menu
            className={`main-menu ${currentMenu.key === 'sources' ? 'brands' : ''} `}
            mode='inline'
            openKeys={[]}
            items={currentMenu.items.map((item) => {
              const isService = Object.values(SERVICES_DATA).some(({ key }) => item.key.includes(key));
              return {
                key: item.key,
                icon: item.icon
                  ? React.cloneElement(item.icon, { className: isService ? 'service-icon' : '' })
                  : null,
                label: (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!searchTerm) {
                        handleMenuClick({ key: item.key });
                      }
                    }}
                  >
                    {item.label}
                  </div>
                ),
                children: item.children,
                disabled: searchTerm,
              };
            })}
          />
          {currentMenu.key === 'services' && <Menu
            className='favorites-recent-menu'
            defaultOpenKeys={isEmpty(starredServicesArray) ? ['recently-visited'] : ['services.favorites']}
            mode='inline'
            items={favoritesAndRecentServices}
            onClick={handleMenuClick}
          />}
          {!isDesktop && currentMenu.key === 'root' && <ThemeToggle />}
        </Space>
      </Drawer>
      {isDesktop && accountMenu()}
      <FeatureRequestModal bug open={featureRequestOpen} onChange={setFeatureRequestOpen} />
    </Header>
  );
};

HeaderComponent.propTypes = {
  auth: PropTypes.object.isRequired,
};

export default HeaderComponent;
