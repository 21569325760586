import React from 'react';

import { services } from '@shared/data/services';
import SimpleService from '@Common/SimpleService';

export default function FastbackgroundcheckAddresses() {
  return (
    <SimpleService
      limitType={null}
      unitName={services.fastbackgroundcheck_addresses.unit}
      title={services.fastbackgroundcheck_addresses.name}
      subTitle={services.fastbackgroundcheck_addresses.description}
      icon={services.fastbackgroundcheck_addresses.icon}
      serviceName='fastbackgroundcheck_addresses'
      queriesLabel='Addresses'
      queriesPlaceholder='321 California Ave, Palo Alto, CA 94306'
      enrichmentFields={services.fastbackgroundcheck_addresses.input_fields}
      fields={services.fastbackgroundcheck_addresses.output_fields}
      supportedEnrichmentServices={[]}
    />
  );
}
