import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function HotelsSearchScraper() {
  return (
    <SimpleService
      unitName='record'
      title={SERVICES_DATA.hotels_search_service.label}
      subTitle='Returns search results'
      queriesLabel='Search URLs'
      icon={SERVICES_DATA.hotels_search_service.icon}
      serviceName='hotels_search'
      learnMoreUrl='https://outscraper.com/hotels-scraper/'
      queriesPlaceholder='https://www.hotels.com/Hotel-Search?destination=Toronto%2C%20Ontario%2C%20Canada&regionId=4089&sort=RECOMMENDED'
      apiTag='Hotels'
    />
  );
}
