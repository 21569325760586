import React from 'react';

import GoogleSearchBase from '@Services//GoogleSearch/Base';
import { SERVICES_DATA } from '@shared/data/services';

export default function GoogleMapsDirectoryPlaces() {
  return (
    <GoogleSearchBase
      title={SERVICES_DATA.google_directory_places_service.label}
      icon={SERVICES_DATA.google_directory_places_service.icon}
      serviceName='google_maps_directory_service'
      queriesPlaceholder='ChIJu7bMNFV-54gR-lrHScvPRX4&#10;0x886916e8bc273979:0x5141fcb11460b226&#10;https://www.google.com/maps/search/restaurants+near+New+York,+NY/@40.7404216,-74.0229033,13z&#10;https://www.google.com/maps/place/?q=place_id:ChIJu7bMNFV-54gR-lrHScvPRX4'
      learnMoreUrl='https://outscraper.com/google-maps-scraper'
      apiTag='Google/paths/~1maps~1directory/get'
      isPerQueryLimit={10000}
      supportedEnrichmentServices={['domains_service', 'emails_validator_service', 'trustpilot_service']}
    />
  );
}
