import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function BookingPricesScraper() {
  return (
    <SimpleService
      limitType={null}
      unitName='hotel'
      title={SERVICES_DATA.booking_prices_service.label}
      subTitle='Returns prices from a list of hotels'
      icon={SERVICES_DATA.booking_prices_service.icon}
      serviceName='booking_prices_service'
      queriesPlaceholder='https://www.booking.com/hotel/tr/old-town-point-amp-spa-antalya.html&#10;old-town-point-amp-spa-antalya'
      apiTag='Booking/paths/~1booking~1prices/get'
    />
  );
}
