import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function YouTubeSearchScraper() {
  return (
    <SimpleService
      unitName='search'
      title={SERVICES_DATA.youtube_search_service.label}
      subTitle='Returns search results'
      icon={SERVICES_DATA.youtube_search_service.icon}
      serviceName='youtube_search_service'
      apiTag='YouTube/paths/~1youtube-search/get'
      queriesPlaceholder='funny cats videos'
      learnMoreUrl='https://outscraper.com/youtube-search-scraper'
      supportedEnrichmentServices={['youtube_channel_service']}
    />
  );
}
