import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function IndeedSearchScraper() {
  return (
    <SimpleService
      unitName='job'
      title={SERVICES_DATA.indeed_search_service.label}
      subTitle='Returns search results'
      icon={SERVICES_DATA.indeed_search_service.icon}
      queriesLabel='Search URLs'
      serviceName='indeed_search'
      learnMoreUrl='https://outscraper.com/indeed-scraper/'
      queriesPlaceholder='https://www.indeed.com/jobs?q=&l=Fremont+Canyon%2C+CA'
      apiTag='Others/paths/~1indeed-search/get'
    />
  );
}
