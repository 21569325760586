import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Input, Button, Popconfirm, notification, Space } from 'antd';

import apiProfile from '@api/apiProfile';
import { isValidEmail } from '@utils/utils';
import { updateGodMode, removeAndBlockUserByIP } from '@redux/actions/profileActions';
import { useMedia } from '@hooks/useMedia';

export default function SupportBlock({ profile, loading, auth }) {
  const { ie } = profile;
  const [investigationEmail, setInvestigationEmail] = useState(ie);
  const investigationEmailNotValid = investigationEmail && !isValidEmail(investigationEmail);
  const dispatch = useDispatch();
  const isTurnOff = ie === investigationEmail || !investigationEmail;
  const { isNotMobile } = useMedia();

  async function onUpdateGodMode(email) {
    try {
      dispatch(updateGodMode(email, afterChangeGodMode));
    } catch (error) {
      notification.error({ message: error.message });
    }
  }

  async function afterChangeGodMode() {
    await auth.renewSession();
    window.location.reload();
  }

  async function onTurnOnGodMode() {
    await onUpdateGodMode(investigationEmail);
  }

  async function onGodModeChange() {
    if (isTurnOff) {
      await onUpdateGodMode(null);
    } else {
      await onTurnOnGodMode();
    }
  }

  async function onVerifyEemail() {
    try {
      await apiProfile.verifyEmail(localStorage.getItem('accessToken'));
      notification.success({ message: 'Verified' });
    } catch (error) {
      notification.error({ message: error.message });
    }
  }

  async function onRemoveAndBlockUserByIP() {
    try {
      dispatch(removeAndBlockUserByIP(investigationEmail));
    } catch (error) {
      notification.error({ message: error.message });
    }
  }

  return <Space direction='vertical' size={16} className='w-100 mb-24'>
    <h3 className='h3 bold'>God Mode Email</h3>
    <Space className='w-100' direction={isNotMobile ? 'horizontal' : 'vertical'}>
      <Input
        disabled={loading}
        style={{ minWidth: '182px' }}
        className='w-100'
        value={investigationEmail}
        onChange={({ target: { value } }) => setInvestigationEmail(value)}
      />
      {ie ? <>
        <Button
          className='w-100'
          title='Turn God Mode off'
          onClick={onGodModeChange}
          loading={loading}
          disabled={investigationEmailNotValid}
        >
          {isTurnOff ? 'Turn Off' : 'Change'}
        </Button>
      </> : <>
        <Button
          className='w-100'
          title='Turn God Mode on (set investigation email)'
          onClick={onTurnOnGodMode}
          loading={loading}
          disabled={investigationEmailNotValid}
        >
          Turn On
        </Button>
      </>}

      {ie && <Button
        className='w-100'
        title='Verify Email'
        onClick={onVerifyEemail}
        loading={loading}
      >
        Verify Email
      </Button>}

      <Popconfirm
        title={`Are you sure you want to remove ${investigationEmail} and his other accoutns by IP?`}
        onConfirm={onRemoveAndBlockUserByIP}
      >
        <Button
          className='w-100'
          danger
          title='Remove and Block Users by IP'
          loading={loading}
          disabled={!isTurnOff}
        >Remove and Block Users by IP</Button>
      </Popconfirm>
    </Space>
  </Space>;
}

SupportBlock.propTypes = {
  profile: PropTypes.object,
  loading: PropTypes.bool,
  auth: PropTypes.object,
};
