import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Progress, Spin, Upload, Tour, notification, message, Space, Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import config from 'react-global-configuration';
import { useLocation, useNavigate } from 'react-router-dom';

import Mixpanel from '@analytics/mixpanel';
import GA from '@analytics/ga';
import { fetchIntegrations } from '@redux/actions/profileActions';
import { isPaidUser, parseQueries, arrayToText, toQueries, splitTaskByKey } from '@utils/utils';
import { sendExtractionTask, validateExtractionTask } from '@redux/actions/queriesActions';
import { getTemplateTask, getDefaultValue, setDefaultValue } from '@utils/defaultProps';
import Href from '@shared/Components/Href';
import ExtractorHeader from './ExtractorHeader';
import Pricing from '@shared/Components/Pricing';
import TaskConfirmationModal from '@Tasks/TaskConfirmation/TaskConfirmationModal';
import ResultsTypeSelect from '@Common/ResultsTypeSelect';
import AdvancedParametersPanel from './AdvancedParametersPanel';
import OutputColumnsFormItem from '@Common/OutputColumnsFormItem';
import TagsFormItem from './TagsFormItem';
import StartTaskButton from './StartTaskButton/StartTaskButton';
import { validateDefault } from '@utils/validation';
import { useMedia } from '@hooks/useMedia';

const { Item } = Form;

const StyledSpin = styled(Spin)`
  margin-left: 10px;
`;

const QUERIES_AMOUNT_THRESHOLD = config.get('queriesSoftLimit');

export default function BaseService({ FormBody, FormBodyExtra, serviceName, title, subTitle, learnMoreUrl,
  startTaskButtonTitle, startTaskButtonIcon, unitName = 'item', taskExtraDefaultParams = {}, validateTask = validateDefault, tourSteps,
  formatTask, deformatTask, splitTask, taskUpdateAfterSubmit = { queries: '', input_file: null, enrich: false, tags: [] }, FormHeader, videoTutorialLink,
  tutorialLink, apiTag, fields, hasResult = true, beta = false, showParams = true, requiredIntegration, icon
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { isDesktop, isMobile } = useMedia();
  const { query: urlQuery } = queryString.parse(location.search);
  const templateTask = getTemplateTask();

  const loading = useSelector(state => state.queriesReducer.loading);
  const integrations = useSelector(state => state.profileReducer.integrations);

  const hasMounted = useRef(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const localTitle = t(`title.${title}`, title) + (beta ? ' (BETA)' : '');
  const localeSubTitle = subTitle ? t(`description.${serviceName}`, subTitle) : '';
  const localeStartTaskButtonTitle = startTaskButtonTitle ? t(`title.export${unitName}`, startTaskButtonTitle) : '';
  const rawSettings = localStorage.getItem(`${serviceName}Settings`);
  const [isTutorialVisible, setIsTutorialVisible] = useState(false);
  const [isSendingTasks, setIsSendingTasks] = useState(false);
  const [sendingTasksProgress, setSendingTasksProgress] = useState(0);
  const [isConfirm, setIsConfirm] = useState(false);
  const [task, setTask] = useState(() => {
    return templateTask ? handleDeformatTask(templateTask) : {
      service_name: serviceName,

      queries: urlQuery ? toQueries(urlQuery) : '',
      input_file: null,

      enrich: false,
      settings: rawSettings ? JSON.parse(rawSettings) : {
        output_extension: getDefaultValue('xlsx', 'outputExtension'),
      },
      tags: [],
      enrichments: [],
      ...taskExtraDefaultParams
    };
  });
  const [isTourVisible, setIsTourVisible] = useState(false);
  const [invalidFields, setInvalidFields] = useState([]);
  const [isTaskButtonClicked, setIsTaskButtonClicked] = useState(false);

  const FIRST_TIME_TOUR_STEPS = [{
    target: () => document.querySelector('.start-task'),
    title: t('tour.startTask', 'Once you select the parameters, you can start the task by clicking this button.'),
  },
  {
    target: () => document.querySelector('.tutorial'),
    title: t('tour.tutorial', 'If you need more help, here you can find tutorials on how to use this service.'),
  }];

  const paidUser = isPaidUser();
  const internalTourSteps = localStorage.getItem('firstTutorial') ? tourSteps : (tourSteps || []).concat(FIRST_TIME_TOUR_STEPS);
  const { settings = {}, input_file, enrich, enrichments = {} } = task;
  const formatedInputFile = input_file ? decodeURI(input_file) : input_file;
  const { output_extension: outputExtension = 'xlsx' } = settings;

  useEffect(() => {
    document.title = localTitle + ' | Outscraper';

    if (!localStorage.getItem(`${serviceName}Tutorial`)) {
      setIsTourVisible(true);
    }

    if (requiredIntegration && isEmpty(integrations)) {
      dispatch(fetchIntegrations(true));
    }
  }, []);

  useEffect(() => {
    if (hasMounted.current) {
      if (requiredIntegration && (isEmpty(integrations) || !(requiredIntegration.toLowerCase() in integrations))) {
        notification.warning({
          message: `${requiredIntegration} integration required`,
          description: <>You will be redirected to the <a href='/integrations'>Integrations</a> page automatically, where you can link your account to {requiredIntegration}.</>,
          duration: 5
        });
        setTimeout(() => navigate('/integrations'), 5000);
      }
    } else {
      hasMounted.current = true;
    }

  }, [integrations]);

  function onFinishTour() {
    setIsTourVisible(false);
    localStorage.setItem(`${serviceName}Tutorial`, '1');
    localStorage.setItem('firstTutorial', '1');
  }

  function updateTask(params, shouldValidate = isTaskButtonClicked) {
    setTask((prevTask) => {
      const updatedTask = { ...prevTask, ...params };

      if (shouldValidate) {
        const { invalidFields } = validateTask(updatedTask);
        setInvalidFields(invalidFields);
      }

      return updatedTask;
    });
  }

  function onSetSettings(value) {
    updateTask({ settings: value });
    localStorage.setItem(`${serviceName}Settings`, JSON.stringify(value));
  }

  function handleSplitTask(task, splitInto) {
    if (input_file) {
      return [{ ...task, split_into: splitInto }];
    } else if (splitTask) {
      return splitTask(task, splitInto);
    } else if (!isEmpty(task.queries)) {
      return splitTaskByKey(task, splitInto, 'queries');
    } else if (!isEmpty(task.locations) && !isEmpty(task.categories)) {
      return splitTaskByKey(task, splitInto, 'locations');
    }
  }

  function handleFormatTask(task) {
    if (formatTask) return formatTask(task);
    else {
      const { queries, cutoff, locations } = task;
      const result = { ...task, queries: parseQueries(queries) };
      if (cutoff) result.cutoff = cutoff.unix();
      if (locations && (typeof locations === 'string' || locations instanceof String)) result.locations = parseQueries(locations);
      return result;
    }
  }

  function handleDeformatTask(task) {
    if (deformatTask) return deformatTask(task);
    else {
      const { queries, cutoff } = task;
      const result = { ...task, queries: arrayToText(queries) };
      if (cutoff) result.cutoff = dayjs(cutoff*1000);
      return result;
    }
  }

  function onValidate() {
    GA.track('button', 'Validate task', serviceName);

    const formatedTask = handleFormatTask(task);
    const { queries, categories, locations } = formatedTask;

    if (queries && queries.length > QUERIES_AMOUNT_THRESHOLD) {
      formatedTask.queries_amount = queries.length;
      formatedTask.queries = queries.slice(0, QUERIES_AMOUNT_THRESHOLD);
    } else if (categories && locations) {
      const statesSelected = locations.filter((item) => (item.match(/>/g) || []).length === 1).length;

      if ((categories.length > 2500 && locations.length > 10) || (categories.length > 50 && statesSelected > 10)) {
        message.warning('Please, reduce the number of categories or locations per single task');
        return;
      }
    }

    setIsConfirm(true);
    dispatch(validateExtractionTask(formatedTask));
  }

  async function onSubmit(splitInto = 1, cost = 0, duration = 0) {
    let progress = 0;

    setIsConfirm(false);
    setIsSendingTasks(true);
    setSendingTasksProgress(progress);

    const formatedTask = handleFormatTask(task);
    if (duration) formatedTask.est = parseInt(duration);

    Mixpanel.track('Send task', { task: formatedTask, cost });
    GA.track('button', 'Send task', serviceName);

    const formatedTasks = splitInto > 1 ? handleSplitTask(formatedTask, splitInto) : [formatedTask];
    const stepProgress = Math.round(1 / formatedTasks.length * 100);
    let isFirstTask = false;

    for (const task of formatedTasks) {
      const isCurrentFirstTask = await dispatch(sendExtractionTask(task));
      if (isCurrentFirstTask) isFirstTask = true;
      progress = progress + stepProgress;
      setSendingTasksProgress(progress);
    }

    setSendingTasksProgress(100);
    setIsSendingTasks(false);

    updateTask(taskUpdateAfterSubmit, false);

    if (isFirstTask) setTimeout(() => navigate('/tasks'), 3000);
  }

  function onCancel() {
    setIsConfirm(false);
  }

  function onRemoveEnrichFile() {
    updateTask({ input_file: null, enrich: false });
  }

  function onOutputExtensionChange(output_extension) {
    updateTask({ settings: { ...settings, output_extension } });
    setDefaultValue(output_extension, 'outputExtension');
  }

  const handleStartTaskButton = () => {
    const { isValid, invalidFields } = validateTask(task);

    setInvalidFields(invalidFields);
    setIsTaskButtonClicked(true);

    if (!isValid) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }

    if (!isValid) {
      return;
    }

    onValidate();
  };

  return <>
    <ExtractorHeader
      title={title}
      localTitle={localTitle}
      subTitle={<Space wrap className='tutorial'>
        {localeSubTitle && <p className='textWrap subTitle'>
          {localeSubTitle}
        </p>}
      </Space>}
      links={<Space wrap='wrap' align='center'>
        <Pricing service={serviceName} unitName={unitName}/>
        {apiTag && <> | <Href href={'/api-docs#tag/' + apiTag}>{t('title.apiDocs', 'API Docs')}</Href></>}
        {learnMoreUrl && <> | <Href external href={learnMoreUrl}>{t('title.productPage', 'Product Page')}</Href></>}
        {tutorialLink && <> | <Href external href={tutorialLink}>📙 {t('title.tutorials', 'Tutorials')}</Href></>}
        {videoTutorialLink && <> | <a
          onClick={() => setIsTutorialVisible(true)}> 🎬 {t('title.videoTutorial', 'Video Tutorial')}</a></>}
      </Space>}
      serviceName={serviceName}
      icon={icon}
    />
    {formatedInputFile && <Form layout='vertical'>
      <Item label={t('title.uploadedFile', 'Uploaded file') + (enrich ? ' (enrichment mode)' : '')}>
        <Upload
          className='base-service-upload'
          fileList={[{ uid: '1', name: formatedInputFile.split('/').pop(), status: 'done', url: formatedInputFile }]}
          onRemove={onRemoveEnrichFile}
        />
      </Item>
    </Form>}
    {FormHeader && !enrich && <FormHeader task={task} updateTask={updateTask} />}

    <Form layout='vertical'>
      <Space direction='vertical' size={16} className='w-100'>

        {!formatedInputFile && <FormBody task={task} updateTask={updateTask} isPaidUser={paidUser} invalidFields={invalidFields} />}
        {FormBodyExtra && <FormBodyExtra task={task} updateTask={updateTask} isPaidUser={paidUser} invalidFields={invalidFields} />}

        {showParams &&
            <AdvancedParametersPanel header={t('title.otherParameters', 'Other parameters (result format, task tags)')}>
              <Row gutter={[16, 16]}>
                {hasResult && <Col xs={24} lg={6} xl={4}>
                  <Item label={t('title.resultExtension', 'Result extension')}>
                    <ResultsTypeSelect value={outputExtension} onChange={onOutputExtensionChange}/>
                  </Item>
                </Col>}
                <Col xs={24} lg={6} xl={4}>
                  <TagsFormItem task={task} onChange={setTask}/>
                </Col>
              </Row>
              {fields && fields.length > 2 &&
                  <Col xs={24} lg={12} xl={8}>
                    <OutputColumnsFormItem
                      value={settings}
                      onChange={onSetSettings}
                      defaultFields={fields}
                      enrichments={enrichments}
                    />
                  </Col>}
            </AdvancedParametersPanel>
        }
      </Space>

      <StartTaskButton
        onClick={handleStartTaskButton}
        loading={loading}
        title={localeStartTaskButtonTitle}
        icon={startTaskButtonIcon}
      />
    </Form>

    <TaskConfirmationModal
      open={isConfirm}
      onOk={onSubmit}
      onRevalidate={onValidate}
      onCancel={onCancel}
      enrichments={enrichments}
    />

    <Modal
      title={<>{t('title.sendingTasks', 'Sending Task(s)')}<StyledSpin size='small' /></>}
      open={isSendingTasks}
      closable={false}
      footer={null}
    >
      <Progress percent={sendingTasksProgress} />
    </Modal>

    <Modal
      centered
      destroyOnClose
      title={t('title.videoTutorial', 'Video Tutorial')}
      width={isDesktop ? 600 : '100%'}
      open={isTutorialVisible}
      onCancel={() => setIsTutorialVisible(false)}
      footer={null}
    >
      <iframe
        allowFullScreen
        width='100%'
        height={isMobile ? '200' : '315'}
        src={videoTutorialLink}
        title={t('title.videoTutorial', 'Video Tutorial')}
        frameBorder='0'
        allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
      />
    </Modal>

    {internalTourSteps && <Tour
      steps={internalTourSteps}
      open={isTourVisible}
      onClose={onFinishTour}
    />}
  </>;
}

BaseService.propTypes = {
  FormBody: PropTypes.func.isRequired,
  FormBodyExtra: PropTypes.func.isRequired,
  serviceName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  learnMoreUrl: PropTypes.string,
  unitName: PropTypes.string,
  startTaskButtonTitle: PropTypes.string,
  startTaskButtonIcon: PropTypes.object,
  taskExtraDefaultParams: PropTypes.object,
  validateTask: PropTypes.func,
  getFormatedTask: PropTypes.func,
  taskUpdateAfterSubmit: PropTypes.object,
  FormHeader: PropTypes.func,
  videoTutorialLink: PropTypes.string,
  tutorialLink: PropTypes.string,
  apiTag: PropTypes.string,
  formatTask: PropTypes.func,
  deformatTask: PropTypes.func,
  splitTask: PropTypes.func,
  fields: PropTypes.array,
  hasResult: PropTypes.bool,
  beta: PropTypes.bool,
  showParams: PropTypes.bool,
  requiredIntegration: PropTypes.string,
  tourSteps: PropTypes.array,
  icon: PropTypes.element
};
