import React from 'react';

import GoogleSearchBase from '@Services//GoogleSearch/Base';
import { SERVICES_DATA } from '@shared/data/services';

export default function GoogleSearchJobs() {
  return (
    <GoogleSearchBase
      title={SERVICES_DATA.google_search_jobs_service.label}
      subTitle='Returns search results'
      icon={SERVICES_DATA.google_search_jobs_service.icon}
      serviceName='google_search_jobs_service'
      apiTag='Google/paths/~1google-search-jobs/get'
      queriesPlaceholder='Python developer California&#10;UI/UX designer New York'
      learnMoreUrl='https://outscraper.com/google-jobs-scraper'
      maxLimitPagesPerQuery={50}
    />
  );
}
