import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function WalmartReviews() {
  return (
    <SimpleService
      unitName='review'
      title={SERVICES_DATA.walmart_reviews_service.label}
      startTaskButtonTitle='Export Reviews'
      subTitle='Returns reviews from a list of products'
      icon={SERVICES_DATA.walmart_reviews_service.icon}
      serviceName='walmart_reviews'
      queriesPlaceholder='https://www.walmart.com/ip/Blackstone-Original-4-Burner-36-Propane-Omnivore-Griddle-with-Hard-Cover/1347629739'
      apiTag='Reviews-and-Comments/paths/~1walmart~1reviews/get'
      defaultParams={{ sort: 'relevancy' }}
      recentCutoffSorting={'submission-desc'}
      sortingOptions={[
        { value: 'relevancy', text: 'Most Relevant' },
        { value: 'helpful', text: 'Most Helpful' },
        { value: 'submission-desc', text: 'Newest to Oldest' },
        { value: 'submission-asc', text: 'Oldest to Newest' },
        { value: 'rating-desc', text: 'High to Low Rating' },
        { value: 'rating-asc', text: 'Low to High Rating' },
      ]}
    />
  );
}
