import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function ShopeeReviews() {
  return (
    <SimpleService
      unitName='review'
      title={SERVICES_DATA.shopee_reviews_service.label}
      startTaskButtonTitle='Export Reviews'
      subTitle='Returns reviews from a list of products'
      icon={SERVICES_DATA.shopee_reviews_service.icon}
      serviceName='shopee_reviews'
      learnMoreUrl='https://outscraper.com/shopee-reviews-scraper/'
      queriesPlaceholder='https://shopee.ph/Apple-iPhone-14-Pro-(6.1-inch)-i.350609763.20351784876'
      defaultParams={{ sort: 0 }}
      sortingOptions={[
        { value: 0, text: 'All' },
        { value: 1, text: 'With comment' },
        { value: 3, text: 'With media' },
      ]}
    />
  );
}
