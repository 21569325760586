import React from 'react';

import GoogleSearchBase from '@Services//GoogleSearch/Base';
import { SERVICES_DATA } from '@shared/data/services';

export default function GoogleSearchEvents() {
  return (
    <GoogleSearchBase
      isGoogleLocationSelect
      title={SERVICES_DATA.google_search_events_service.label}
      subTitle='Returns search results'
      icon={SERVICES_DATA.google_search_events_service.icon}
      serviceName='google_search_events_service'
      queriesPlaceholder='beer festivals minnesota'
    />
  );
}
