import React from 'react';

import GoogleSearchBase from '@Services//GoogleSearch/Base';
import { SERVICES_DATA } from '@shared/data/services';

export default function GoogleSearchImages() {
  return (
    <GoogleSearchBase
      isPerQueryLimit
      isGoogleLocationSelect
      unitName='image'
      title={SERVICES_DATA.google_search_images_service.label}
      subTitle='Returns search results'
      icon={SERVICES_DATA.google_search_images_service.icon}
      serviceName='google_search_images_service_v2'
      queriesPlaceholder='Yaught&#10;Wallpaper&#10;Skyscraper'
      apiTag='Google/paths/~1google-search-images/get'
    />
  );
}
