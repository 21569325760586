import React from 'react';
import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function SamsClubProducts() {
  return (
    <SimpleService
      unitName='product'
      title={SERVICES_DATA.samsclub_products_service.label}
      subTitle='Returns product listings'
      icon={SERVICES_DATA.samsclub_products_service.icon}
      serviceName='samsclub_products'
      learnMoreUrl='https://outscraper.com/samsclub-scraper/'
      apiTag='SamsClub/paths/~1samsclub~1products/get'
      queriesLabel='Product, category or search URLs'
      queriesPlaceholder='https://www.samsclub.com/p/members-mark-facial-tissue-12-boxes/prod21292138&#10;https://www.samsclub.com/c/groceries-food/1444&#10;https://www.samsclub.com/s/furniture'
    />
  );
}
