import React from 'react';

import { services } from '@shared/data/services';
import SimpleService from '@Common/SimpleService';

export default function LinkedinPeopleScraper() {
  return (
    <SimpleService
      limitType={null}
      unitName={services.linkedin_profiles.unit}
      queriesLabel='URLs to profiles, profile IDs'
      title={services.linkedin_profiles.name}
      subTitle={services.linkedin_profiles.description}
      icon={services.linkedin_profiles.icon}
      serviceName='linkedin_profiles'
      queriesPlaceholder='realvlad&#10;https://www.linkedin.com/in/realvlad/'
      enrichmentFields={services.linkedin_profiles.input_fields}
      supportedEnrichmentServices={[]}
    />
  );
}
