import React from 'react';

import { Col, Form, Select } from 'antd';
import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

const { Item } = Form;
const { Option } = Select;

export default function TripadvisorSearch() {
  return (
    <SimpleService
      unitName='place'
      title={SERVICES_DATA.tripadvisor_search_service.label}
      subTitle='Returns data from Tripadvisor'
      icon={SERVICES_DATA.tripadvisor_search_service.icon}
      serviceName='tripadvisor_search'
      queriesPlaceholder='rome&#10;https://www.tripadvisor.com/Search?q=rome'
      apiTag='Others/paths/~1tripadvisor-search/get'
      maxLimit={1000}
      ExtraItems={({ updateTask, task }) => {
        const { search_type = 'a' } = task;

        return <Col xs={24} lg={6} xl={4}>
          <Item label='Search type'>
            <Select value={search_type} onChange={(v) => updateTask({ search_type: v })}>
              <Option key='a' value='a'>All Results</Option>
              <Option key='h' value='h'>Hotels</Option>
              <Option key='e' value='e'>Restaurants</Option>
              <Option key='A' value='A'>Things to Do</Option>
              <Option key='v' value='v'>Vacation Rentals</Option>
            </Select>
          </Item>
        </Col>;
      }}
    />
  );
}
