import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function TwitterProfilesScraper() {
  return (
    <SimpleService
      limitType={null}
      unitName='page'
      title={SERVICES_DATA.twitter_profiles_service.label}
      subTitle='Returns data from a list of profiles'
      icon={SERVICES_DATA.twitter_profiles_service.icon}
      queriesLabel='Usernames, pages URLs'
      serviceName='twitter_profiles'
      queriesPlaceholder='outscraper&#10;https://twitter.com/outscraper'
      apiTag='Others/paths/~1twitter~1profiles/get'
    />
  );
}
