import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, InputNumber, Checkbox, Space } from 'antd';

import { services } from '@shared/data/services';
import SimpleService from '@Common/SimpleService';
import PreferredContactsItem from '@Common/PreferredContactsItem';

const { Item } = Form;

export default function ContactsFinder() {
  return (
    <SimpleService
      limitType={null}
      unitName={services.contacts_finder_service.unit}
      title={services.contacts_finder_service.name}
      subTitle={services.contacts_finder_service.description}
      icon={services.contacts_finder_service.icon}
      apiTag='Email-Related/paths/~1contacts-finder/get'
      serviceName='contacts_finder_service'
      queriesPlaceholder='company.com&#10;company2.com'
      queriesLabel='Domains or URLs'
      defaultParams={{ limit_per_company: 5 }}
      ExtraItems={({ updateTask, task }) => <ExtraItems updateTask={updateTask} task={task} />}
    />
  );
}

export function ExtraItems({ updateTask, task, isModal = false }) {
  const { limit_per_company = 3, ignore_without_title, ignore_without_linkedin } = task;

  return  (
    <Space className='w-100' size={16} direction='vertical'>
      <Col xs={24} lg={isModal ? 24 : 6} xl={isModal ? 24 : 4}>
        <PreferredContactsItem value={task} onChange={updateTask} isPaidUser/>
      </Col>
      <Space>
        <Checkbox checked={ignore_without_title} onChange={() => updateTask({ ignore_without_title: !ignore_without_title })}>
            Only with title
        </Checkbox>
        <Checkbox checked={ignore_without_linkedin} onChange={() => updateTask({ ignore_without_linkedin: !ignore_without_linkedin })}>
            Only with LinkedIn
        </Checkbox>
      </Space>
      <Col xs={24}>
        <Item
          label='Number of contacts per one company'
          wrapperCol={{
            xs: { span: 24 },
            lg: { span: isModal ? 24 : 6 },
            xl: { span: isModal ? 24 : 4 },
          }}
        >
          <InputNumber
            className='w-100'
            min={1}
            step={1}
            max={1000}
            value={limit_per_company}
            onChange={(v) => updateTask({ limit_per_company: parseInt(v) || 1 })}
          />
        </Item>
      </Col>
    </Space>
  );
}

ExtraItems.propTypes = {
  updateTask: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
  isModal: PropTypes.bool,
};
