import React from 'react';
import { Col, Form, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const { Item } = Form;

import SimpleService from '@Common/SimpleService';
import LanguageSelect from '@Common/Google/LanguageSelect';
import RegionSelect from '@Common/Google/RegionSelect';
import { SERVICES_DATA } from '@shared/data/services';

export default function GoogleSearchShopping() {
  const { t } = useTranslation();
  const location = useLocation();

  const { hl: urlLanguage } = queryString.parse(location.search);

  return (
    <SimpleService
      title={SERVICES_DATA.google_search_shopping_service.label}
      subTitle='Returns search results'
      icon={SERVICES_DATA.google_search_shopping_service.icon}
      serviceName='google_search_shopping_service'
      queriesPlaceholder='Iphone 13&#10;Dell monitor&#10;https://www.google.com/search?q=iphone...'
      apiTag='Google/paths/~1google-search-shopping/get'
      unitName='product'
      defaultParams={{
        limit_per_query: 100,
        region: 'US',
        language: urlLanguage ? urlLanguage : (localStorage.getItem('language') || 'en'),
      }}
      ExtraItems={({ updateTask, task }) => {
        const { region, language } = task;

        return (
          <Space size={16} className='w-100' direction='vertical'>
            <Col xs={24} lg={6} xl={4}>
              <Item label={t('title.language')}>
                <LanguageSelect value={language} onChange={(v) => updateTask({ language: v })} />
              </Item>
            </Col>

            <Col xs={24} lg={6} xl={4}>
              <Item label={t('title.Region')}>
                <RegionSelect allowClear={false} value={region} onChange={(v) => updateTask({ region: v })} />
              </Item>
            </Col>
          </Space>
        );
      }}
    />
  );
}
