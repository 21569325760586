import React from 'react';
import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function BiggestBookProducts() {
  return (
    <SimpleService
      unitName='product'
      title={SERVICES_DATA.biggestbook_products_service.label}
      subTitle='Returns product listings'
      icon={SERVICES_DATA.biggestbook_products_service.icon}
      serviceName='biggestbook_products'
      learnMoreUrl='https://outscraper.com/biggestbook-scraper/'
      apiTag='BiggestBook/paths/~1biggestbook~1products/get'
      queriesLabel='Product, category or search URLs'
      queriesPlaceholder='https://www.biggestbook.com/product/12345&#10;https://www.biggestbook.com/category/books&#10;https://www.biggestbook.com/search?q=bestsellers'
    />
  );
}
