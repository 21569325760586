import React from 'react';

import GoogleSearchBase from '@Services//GoogleSearch/Base';
import { SERVICES_DATA } from '@shared/data/services';

export default function GoogleSearchVideos() {
  return (
    <GoogleSearchBase
      isGoogleLocationSelect
      title={SERVICES_DATA.google_search_videos_service.label}
      subTitle='Returns search results'
      icon={SERVICES_DATA.google_search_videos_service.icon}
      serviceName='google_search_videos_service'
      queriesPlaceholder='Bitcoin&#10;Outscraper'
      apiTag='Google/paths/~1google-search-videos/get'
    />
  );
}
