import React from 'react';

import { Form, Select, InputNumber, Space, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import SimpleService from '@Common/SimpleService';

import InfoTooltip from '@shared/Components/InfoTooltip';
import { SERVICES_DATA } from '@shared/data/services';

const { Item } = Form;
const { Option } = Select;

export default function ZillowScraper() {
  const { t } = useTranslation();

  const skipLabelWithTooltip = <Space align='center'>
    {t('title.Skip')}
    <InfoTooltip
      title={t('description.skip')}/>
  </Space>;

  return (
    <SimpleService
      unitName='listing'
      title={SERVICES_DATA.zillow_search_service.label}
      subTitle='Returns data from Zillow'
      icon={SERVICES_DATA.zillow_search_service.icon}
      serviceName='zillow_search_service'
      queriesPlaceholder='Jacksonville, FL&#10;https://www.zillow.com/jacksonville-fl/sold/?searchQueryState=...'
      learnMoreUrl='https://outscraper.com/zillow-scraper'
      tutorialLink='https://outscraper.com/real-estate-zillow-data-extraction-no-coding-scraper/'
      apiTag='Others/paths/~1zillow-search/get'
      maxLimit={1000}
      ExtraItems={({ updateTask, task }) => {
        const { main_filter = 'for_sale', skip = 0 } = task;

        return <Space className='w-100' size={16} direction='vertical'>

          <Col xs={24} lg={6} xl={4}>
            <Item label={t('title.filtering')}>
              <Select value={main_filter} onChange={(v) => updateTask({ main_filter: v })}>
                <Option key='for_sale' value='for_sale'>For Sale</Option>
                <Option key='for_rent' value='for_rent'>For Rent</Option>
                <Option key='sold' value='sold'>Sold</Option>
              </Select>
            </Item>
          </Col>

          <Col xs={24} lg={6} xl={4}>
            <Item label={skipLabelWithTooltip}>
              <InputNumber
                className='w-100'
                min={0}
                max={410}
                step={41}
                value={skip}
                onChange={(v) => updateTask({ skip: Math.round(parseInt(v) / 41) * 41 || 0 })}
              />
            </Item>
          </Col>
        </Space>;
      }}
      supportedEnrichmentServices={['whitepages_addresses', 'fastbackgroundcheck_addresses']}
    />
  );
}
