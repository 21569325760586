import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function YelpPhotosScraper() {
  return (
    <SimpleService
      unitName='photo'
      title={SERVICES_DATA.yelp_photos_service.label}
      subTitle='Returns photos from a list of businesses'
      icon={SERVICES_DATA.yelp_photos_service.icon}
      serviceName='yelp_photos'
      queriesPlaceholder='https://www.yelp.com/biz/cancha-boutique-gastrobar-san-francisco&#10;eggcellent-waffles-san-francisco&#10;iXoLJWjbcXCO43RT-H0uQQ'
    />
  );
}
