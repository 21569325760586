import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Input, Button, Tooltip, Modal, Popconfirm, notification, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import Mixpanel from '@analytics/mixpanel';
import apiProfile from '@api/apiProfile';
import { fetchProfile } from '@redux/actions/profileActions';
import { useMedia } from '@hooks/useMedia';

export default function PromotionCode({ existingCouponName }) {
  const [promotionCode, setPromotionCode] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isNotMobile } = useMedia();
  const withoutCoupon = !existingCouponName;

  async function apply() {
    setLoading(true);
    try {
      const { data } = await apiProfile.applyPromotionCode(promotionCode);

      Mixpanel.track('Apply promo code', { promotionCode });

      notification.success({ message: `Coupon "${data}" is applied` });

      if (data.toLowerCase().includes('appsumo')) {
        localStorage.setItem('appsumo', '1');

        Modal.success({
          onOk: () => window.open('https://outscraper.com/appsumo-faq', '_blank'),
          title: 'Hello, Sumo-lings!',
          content: <>
            You are ready to go using the services! Before you begin you also might want to read
            <a target='_blank'
              rel='noopener noreferrer'
              href='https://outscraper.com/appsumo-faq'> this article</a> about the most common questions of AppSumo customers.
          </>,
        });
      }

      setPromotionCode('');

      dispatch(fetchProfile());
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      setLoading(false);
    }
  }

  async function handleKeyDown({ key }) {
    if (key === 'Enter' && promotionCode.length >= 5) {
      await apply();
    }
  }

  return <Space align='middle' className={isNotMobile ? 'w-100' : 'first-space-item-full'}>
    <Input
      className='w-100'
      disabled={loading}
      value={promotionCode}
      onKeyDown={handleKeyDown}
      onChange={({ target: { value } }) => setPromotionCode(value)}
    />
    {promotionCode.length >= 5 && (
      <Popconfirm
        title={<>
          <Tooltip title='Currently, we support only one special offer per account.'>
            Are you sure to <strong className='warning'>replace</strong> your existing offer &quot;<strong>{existingCouponName}</strong>&quot;?
          </Tooltip>
        </>}
        okText={t('action.yes')}
        cancelText={t('action.no')}
        onConfirm={apply}
        disabled={withoutCoupon}
      >
        <Button
          className='reset-padding'
          title='Apply Promotion Code'
          onClick={withoutCoupon && apply}
          loading={loading}
        >Apply</Button>
      </Popconfirm>
    )}
  </Space>;
}

PromotionCode.propTypes = {
  existingCouponName: PropTypes.string,
};
