import React from 'react';
import { Form, Select, Input, Col, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import SimpleService from '@Common/SimpleService';
import DomainSelectItem from '@Common/Amazon/DomainSelectItem';
import { SERVICES_DATA } from '@shared/data/services';

const { Item } = Form;
const { Option } = Select;

export default function AmazonProductsExtractor() {
  const { t } = useTranslation();

  return (
    <SimpleService
      title={SERVICES_DATA.amazon_products_service.label}
      subTitle='Returns product listings'
      icon={SERVICES_DATA.amazon_products_service.icon}
      serviceName='amazon_products_service_v2'
      unitName='product'
      learnMoreUrl='https://outscraper.com/amazon-scraper'
      apiTag='Amazon/paths/~1amazon~1products/get'
      queriesLabel='Amazon ASINs or product URLs'
      queriesPlaceholder='B00K0QKBM6&#10;https://www.amazon.com/s?k=golf+clubs&ref=nb_sb_noss_1'
      defaultParams={{
        limit_per_query: 100,
        domain: 'amazon.com',
        postal_code: '',
      }}
      ExtraItems={({ updateTask, task }) => {
        const { domain, language, currency, postal_code } = task;

        return <Space size={16} className='w-100' direction='vertical'>
          <Col xs={24} lg={6} xl={4}>
            <DomainSelectItem
              value={domain}
              onChange={(domain) => updateTask({ domain })}
            />
          </Col>

          <Col xs={24} lg={6} xl={4}>
            <Item label={t('title.deliveryPostcode')}>
              <Input
                placeholder='11201'
                value={postal_code}
                onChange={({ target }) => updateTask({ postal_code: target.value })}
              />
            </Item>
          </Col>

          <Col xs={24} lg={6} xl={4}>
            <Item label={t('title.language')}>
              <Select
                showSearch
                value={language}
                onChange={(language) => updateTask({ language })}
              >
                <Option key='en' value='en'>EN</Option>
                <Option key='de' value='de'>DE</Option>
                <Option key='fr' value='fr'>FR</Option>
                <Option key='it' value='it'>IT</Option>
                <Option key='es' value='es'>ES</Option>
                <Option key='nl' value='nl'>NL</Option>
                <Option key='pt' value='pt'>PT</Option>
                <Option key='pl' value='pl'>PL</Option>
                <Option key='tr' value='tr'>TR</Option>
                <Option key='ar' value='ar'>AR</Option>
                <Option key='ja' value='ja'>JA</Option>
              </Select>
            </Item>
          </Col>

          <Col xs={24} lg={6} xl={4}>
            <Item label={t('title.currency')}>
              <Select
                showSearch
                value={currency}
                onChange={(currency) => updateTask({ currency })}
              >
                <Option key='USD' value='USD'>USD</Option>
                <Option key='EUR' value='EUR'>EUR</Option>
                <Option key='GBR' value='GBR'>GBR</Option>
                <Option key='CHF' value='CHF'>CHF</Option>
                <Option key='PLN' value='PLN'>PLN</Option>
                <Option key='SEK' value='SEK'>SEK</Option>
                <Option key='DKK' value='DKK'>DKK</Option>
                <Option key='CZK' value='CZK'>CZK</Option>
                <Option key='NOK' value='NOK'>NOK</Option>
              </Select>
            </Item>
          </Col>
        </Space>;
      }}
    />
  );
}
