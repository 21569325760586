import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StarOutlined, StarFilled } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Space } from 'antd';

import Href from '@shared/Components/Href';
import { getServiceUrl } from '@utils/utils';
import { newServices } from '@shared/data/services';
import { useMedia } from '@hooks/useMedia';
import { fetchUserSettings, updateUserSettings } from '@redux/actions/profileActions';

function ExtractorHeader({ title, localTitle, href, subTitle, serviceName, links, icon }) {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { isDesktop } = useMedia();
  const { userSettings = { favorites: [] } } = useSelector(state => state.profileReducer);

  const favorites = userSettings?.favorites ? userSettings.favorites : [];
  const [starred, setStarred] = useState(false);
  const serviceUrl = getServiceUrl(serviceName);

  useEffect(() => {
    dispatch(fetchUserSettings());
  }, [dispatch]);

  useEffect(() => {
    const isStarred = favorites.some(service => service.title === title);
    setStarred(isStarred);
  }, [favorites, title]);

  function onStarClick() {
    const updatedFavorites = [...(userSettings?.favorites || [])];

    if (starred) {
      const index = updatedFavorites.findIndex(service => service.title === title);
      if (index !== -1) {
        updatedFavorites.splice(index, 1);
      }
    } else {
      updatedFavorites.push({ title, href: serviceUrl });
      if (updatedFavorites.length > 10) updatedFavorites.shift();
    }

    dispatch(updateUserSettings({
      ...userSettings,
      favorites: updatedFavorites
    }));

    navigate(`${location.pathname}${location.search}`, { replace: true });
  }

  useEffect(() => {
    const recentServices = JSON.parse(localStorage.getItem('recentServices') || '[]');
    const recentNewServices = JSON.parse(localStorage.getItem('recentNewServices') || '[]');

    let updatedRecentServices = [...recentServices];

    if (!updatedRecentServices.some(service => service.title === title)) {
      updatedRecentServices.push({ title, href: serviceUrl });
      if (updatedRecentServices.length > 10) updatedRecentServices.shift();

      localStorage.setItem('recentServices', JSON.stringify(updatedRecentServices));
    }

    if (serviceUrl in newServices && !recentNewServices.includes(serviceUrl)) {
      const updatedRecentNewServices = [...recentNewServices, serviceUrl];
      localStorage.setItem('recentNewServices', JSON.stringify(updatedRecentNewServices));
    }
  }, []);

  return <Space size={16} direction={isDesktop ? 'horizontal' : 'vertical'} align='start' className='extractor-header w-100 justifySpaceBetween mb-24'>
    {subTitle ? <Space size={16} direction='vertical'>
      <Space align='center' className='title'>
        {icon}
        <h1 className='h1'>{localTitle}</h1>
        {serviceName && (starred ?
          <StarFilled className='star-icon' title='unstar' onClick={onStarClick} /> :
          <StarOutlined className='star-icon' title='star' onClick={onStarClick} />)
        }
      </Space>
      {subTitle}
    </Space> : <Href href={href} external>learn more</Href>}
    {links}
  </Space>;
}

ExtractorHeader.propTypes = {
  title: PropTypes.string.isRequired,
  localTitle: PropTypes.string.isRequired,
  href: PropTypes.string,
  subTitle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  serviceName: PropTypes.string,
  links: PropTypes.object,
  icon: PropTypes.element
};

const areEqual = (prevProps, nextProps) => {
  return prevProps.title === nextProps.title;
};

export default React.memo(ExtractorHeader, areEqual);
