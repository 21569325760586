import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Input, message, Popconfirm, Button, Space, Col } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation, Trans } from 'react-i18next';

import { createAPIToken, revokeAPIToken } from '@redux/actions/profileActions';
import { warningMessage } from '@shared/Components/PaymentInfo';

export default function APIToken({ token, loading, disabled, accountNotValid, accountStatus }) {
  const isDisabled = (loading || disabled);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function onClickFromInput() {
    if (token) {
      onCopy();
    } else {
      onGenerateAPIToken();
    }
  }

  function onGenerateAPIToken() {
    if (accountNotValid) {
      message.warning((accountStatus === 'open_invoices' || accountStatus === 'excessive_open_invoices') ? <>
        <Trans i18nKey='title.closeInvoices' />.
        <Button type='link' href='/invoices' target='_blank' rel='noopener noreferrer'><Trans i18nKey='action.viewInvoices' /></Button>
      </> : warningMessage, 5000);
    } else {
      dispatch(createAPIToken());
    }
  }

  function onRevokeAPIToken() {
    dispatch(revokeAPIToken());
  }

  function onCopy() {
    let apiToken = document.querySelector('.api-token');
    apiToken.select();
    document.execCommand('copy');

    message.success(t('description.copied'));
  }

  return <Col xs={24} md={12}>
    <Space className='first-space-item-full'>
      <Input
        readOnly
        className='api-token copieble w-100'
        value={token ? token : t('action.generateAPItoken')}
        disabled={isDisabled}
        onClick={onClickFromInput}
      />
      <Button
        className='noPadding'
        title={t('title.copyToClipboard')}
        icon={<FontAwesomeIcon icon={faCopy}/>}
        onClick={onCopy}
        disabled={isDisabled || !token}
      />
      <Popconfirm
        title={'Generate new API token?' + (token ? ' (previous token will be revoked)' : '')}
        okText={t('action.ok')}
        cancelText={t('action.cancel')}
        onConfirm={onGenerateAPIToken}
        disabled={isDisabled}
      >
        <Button
          className='noPadding'
          title={t('action.update')}
          icon={<FontAwesomeIcon icon={faSync}/>}
          disabled={isDisabled}
        />
      </Popconfirm>
      {token && (
        <Popconfirm
          title={t('title.areYouSure')}
          okText={t('action.yes')}
          cancelText={t('action.no')}
          onConfirm={onRevokeAPIToken}
          disabled={isDisabled}
        >
          <Button
            className='noPadding'
            title={t('action.remove')}
            icon={<FontAwesomeIcon icon={faTrash}/>}
            disabled={isDisabled}
          />
        </Popconfirm>
      )}
    </Space>
  </Col>;
}

APIToken.propTypes = {
  token: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  accountNotValid: PropTypes.bool,
  accountStatus: PropTypes.string,
};
