import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function YelpReviewsScraper() {
  return (
    <SimpleService
      unitName='review'
      title={SERVICES_DATA.yelp_reviews_service.label}
      startTaskButtonTitle='Export Reviews'
      subTitle='Returns reviews from a list of businesses'
      icon={SERVICES_DATA.yelp_reviews_service.icon}
      serviceName='yelp_reviews_service'
      queriesPlaceholder='https://www.yelp.com/biz/cancha-boutique-gastrobar-san-francisco&#10;eggcellent-waffles-san-francisco&#10;iXoLJWjbcXCO43RT-H0uQQ'
      apiTag='Reviews-and-Comments/paths/~1yelp~1reviews/get'
      defaultParams={{ sort: 'relevance_desc' }}
      recentCutoffSorting={'date_desc'}
      sortingOptions={[
        { value: 'relevance_desc', text: 'Yelp Sort' },
        { value: 'date_desc', text: 'Newest First' },
        { value: 'date_asc', text: 'Older First' },
        { value: 'rating_desc', text: 'Highest Rated' },
        { value: 'rating_asc', text: 'Lowest Rated' },
        { value: 'elites_desc', text: 'Elites' },
      ]}
    />
  );
}
