import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function GlassdoorSearchScraper() {
  return (
    <SimpleService
      unitName='job'
      title={SERVICES_DATA.glassdoor_search_service.label}
      subTitle='Returns search results'
      queriesLabel='Search URLs'
      icon={SERVICES_DATA.glassdoor_search_service.icon}
      serviceName='glassdoor_search'
      learnMoreUrl='https://outscraper.com/glassdoor-scraper/'
      queriesPlaceholder='https://www.glassdoor.com/Job/los-angeles-ca-us-python-jobs-SRCH_IL.0,17_IC1146821_KO18,24.htm'
    />
  );
}
