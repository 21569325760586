import React from 'react';
import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function BootsProducts() {
  return (
    <SimpleService
      unitName='product'
      title={SERVICES_DATA.boots_products_service.label}
      subTitle='Returns product listings'
      icon={SERVICES_DATA.boots_products_service.icon}
      serviceName='boots_products'
      learnMoreUrl='https://outscraper.com/boots-scraper/'
      apiTag='Boots/paths/~1boots~1products/get'
      queriesLabel='Product, category or search URLs'
      queriesPlaceholder = 'https://www.boots.com/health-and-pharmacy&#10;https://www.boots.com/health-and-pharmacy/medical-devices&#10;https://www.boots.com/health-and-pharmacy/medical-devices/blood-pressure-monitors'
    />);
}
