import React from 'react';
import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function TescoProducts() {
  return (
    <SimpleService
      unitName='product'
      title={SERVICES_DATA.tesco_products_service.label}
      subTitle='Returns product listings'
      icon={SERVICES_DATA.tesco_products_service.icon}
      serviceName='tesco_products'
      learnMoreUrl='https://outscraper.com/tesco-products-scraper/'
      apiTag='Tesco/paths/~1tesco~1products/get'
      queriesLabel='Product, category or search URLs'
      queriesPlaceholder='https://www.tesco.com/groceries/en-GB/products/305096546&#10;https://www.tesco.com/groceries/en-GB/shop/fresh-food/fresh-vegetables&#10;https://www.tesco.com/groceries/en-GB/search?query=organic%20milk'
    />
  );
}
