import React from 'react';
import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function MacysProducts() {
  return (
    <SimpleService
      unitName='product'
      title={SERVICES_DATA.macys_products_service.label}
      subTitle='Returns product listings'
      icon={SERVICES_DATA.macys_products_service.icon}
      serviceName='macys_products'
      learnMoreUrl='https://outscraper.com/macys-scraper/'
      apiTag='Macys/paths/~1macys~1products/get'
      queriesLabel='Product, category or search URLs'
      queriesPlaceholder='https://www.macys.com/shop/product/tommy-hilfiger-mens-custom-fit-new-england-solid-oxford-shirt?ID=4886827&#10;https://www.macys.com/shop/mens-clothing/mens-suits?id=17788&#10;https://www.macys.com/shop/featured/womens-dresses'
    />
  );
}
