import React from 'react';
import { Form, Select } from 'antd';

import SimpleService from './Common/SimpleService';

const { Item } = Form;
const { Option } = Select;

export default function DomainContactsFinder() {
  return (
    <SimpleService
      unitName='domain'
      limitType={null}
      title='Domain Contacts Finder (LI + Google)'
      icon={null}
      serviceName='domain_contacts_finder'
      queriesPlaceholder='outscraper.com'
      ExtraItems={({ updateTask, task }) => {
        const { titles } = task;

        return <>
          <Item label='Preferred title'>
            <Select
              showSearch
              value={titles}
              mode='tags'
              maxTagCount={3}
              optionFilterProp='children'
              onChange={(titles) => updateTask({ titles })}
            >
              <Option key='CEO' value='CEO'>CEO</Option>
              <Option key='CTO' value='CTO'>CTO</Option>
              <Option key='Owner' value='Owner'>Owner</Option>
              <Option key='Founder' value='Founder'>Founder</Option>
              <Option key='CMO' value='CMO'>CMO</Option>
              <Option key='Marketer' value='Marketer'>Marketer</Option>
            </Select>
          </Item>
        </>;
      }}
    />
  );
}
