import React from 'react';

import SimpleService from '@Common/SimpleService';
import { SERVICES_DATA } from '@shared/data/services';

export default function ExpediaSearchScraper() {
  return (
    <SimpleService
      unitName='record'
      title={SERVICES_DATA.expedia_search_service.label}
      subTitle='Returns search results'
      queriesLabel='Search URLs'
      icon={SERVICES_DATA.expedia_search_service.icon}
      serviceName='expedia_search'
      learnMoreUrl='https://outscraper.com/expedia-scraper/'
      queriesPlaceholder='https://www.expedia.com/Hotel-Search?destination=Ankara...'
      apiTag='Expedia'
    />
  );
}
