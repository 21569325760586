import React from 'react';

import { services } from '@shared/data/services';
import SimpleService from './Common/SimpleService';

export default function SimilarWeb() {
  return (
    <SimpleService
      limitType={null}
      unitName={services.similarweb.unit}
      title={services.similarweb.name}
      subTitle={services.similarweb.description}
      icon={services.similarweb.icon}
      serviceName='similarweb'
      queriesPlaceholder='company.com&#10;company2.com'
      queriesLabel='Domains or URLs'
      apiTag='Other-Services/paths/~1similarweb/get'
      enrichmentFields={services.similarweb.input_fields}
      supportedEnrichmentServices={[]}
    />
  );
}
